import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import { RoleType } from 'src/shared/config/types'

import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'

import { CommentIntegrationModal } from '../CommentIntegrationModal/CommentIntegrationModal'

import styles from './RespondedBlogger.module.scss'

interface RespondedBloggerPropsType extends BloggerPropsType {
  role: RoleType
  onConfirmClick?(): void
  onRejectClick?(): void
  children?: ReactNode
}

export const RespondedBlogger: FC<RespondedBloggerPropsType> = ({
  role,
  onConfirmClick,
  onRejectClick,
  children,
  ...props
}) => {
  const [isOpenCommentModal, setOpenCommentModal] = useState(false)
  const [isTextClamped, setTextClamped] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (textRef.current) {
      const isClamped = textRef.current.scrollHeight > textRef.current.offsetHeight
      setTextClamped(isClamped)
    }
  }, [props.commentIntegration])

  const handleReadMoreClick = () => {
    setOpenCommentModal(true)
  }

  return (
    <Blogger {...props}>
      {props.commentIntegration && (
        <div className={styles.Item}>
          <span className={styles.Title}>Комментарий:</span>
          <div className={styles.Container}>
            <div className={styles.Value} ref={textRef}>
              {props.commentIntegration}
            </div>
            {isTextClamped && (
              <span className={styles.ReadMore} onClick={handleReadMoreClick}>
                Cмотреть ещё
              </span>
            )}
          </div>
        </div>
      )}

      {role === RoleType.CUSTOMER && (
        <Flex flexDirection="column" alignItems="center" gap={8}>
          <CabinetActionButton onClick={onConfirmClick}>Подтвердить участника</CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onRejectClick}>
            Отклонить участника
          </CabinetActionButton>
        </Flex>
      )}
      {children}
      {isOpenCommentModal && (
        <CommentIntegrationModal
          comment={props.commentIntegration || ''}
          onClose={() => {
            setOpenCommentModal(false)
          }}
        />
      )}
    </Blogger>
  )
}
