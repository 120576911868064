import React, { FC, FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, CreatePaymentDto, HttpResponse, PaymentResponseDto } from 'src/shared/api'
import { ReactComponent as BaseTariff } from 'src/shared/assets/cabinet_tariff_1.svg'
import { ReactComponent as StandartTariff } from 'src/shared/assets/cabinet_tariff_2.svg'
import { ReactComponent as MaxTariff } from 'src/shared/assets/cabinet_tariff_3.svg'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Checkbox } from 'src/shared/ui/Checkbox'
import { Flex } from 'src/shared/ui/Flex'

import styles from './TariffsBlock.module.scss'

export const TariffsBlock: FC = () => {
  const [balance, setBalance] = useState(20)

  const { mutateAsync: initPaymentMutation, isPending: isLoading } = useMutation<
    HttpResponse<PaymentResponseDto, CommonMessageResponse | void>,
    HttpResponse<PaymentResponseDto, CommonMessageResponse | void>,
    CreatePaymentDto
  >({
    mutationFn: AdminAPI.api.paymentControllerInitializePayment,
  })

  const handleSubmit = useCallback(async () => {
    const initPaymentToastId = toast.loading('Создаём платёж')

    await initPaymentMutation(
      {
        description: 'Покупка интеграций MarketIQ',
        quantity: balance,
      },
      {
        // TODO - подключить правильные типы
        onSuccess: ({ data }: any) => {
          if ('PaymentURL' in data) {
            window.location.href = String(data.PaymentURL)
          }
          if (!data.Success) {
            toast.error(data.Message + ' ' + data.Details, {
              id: initPaymentToastId,
            })
          } else {
            toast.success('Платёж создан! ✨', { id: initPaymentToastId })
          }
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Произошла ошибка при создании платежа', {
            id: initPaymentToastId,
          })
        },
      },
    )
  }, [balance, initPaymentMutation])

  return (
    <Flex flexDirection="column" className={styles.Tariffs}>
      <h2 className={styles.Tariffs__title}>Тарифы</h2>
      <Flex flexDirection="column" className={styles.TariffsList}>
        <label className={styles.TariffsItem}>
          <Flex gap={12} alignItems="center">
            <BaseTariff />
            <Flex flexDirection="column" gap={8} alignItems="flex-start">
              <p className={styles.TariffsItem__Title}>Lite</p>
              <p className={styles.TariffsItem__Integrations}>20 интеграций</p>
            </Flex>
          </Flex>
          <strong className={styles.TariffsItem__Price}>10 000 ₽</strong>
          <Checkbox checked={balance === 20} onChange={() => setBalance(20)} />
        </label>
        <hr className={styles.Separator} />
        <label className={styles.TariffsItem}>
          <Flex gap={12} alignItems="center">
            <StandartTariff />
            <Flex flexDirection="column" gap={8} alignItems="flex-start">
              <p className={styles.TariffsItem__Title}>Optimal</p>
              <p className={styles.TariffsItem__Integrations}>50 интеграций</p>
            </Flex>
          </Flex>
          <strong className={styles.TariffsItem__Price}>20 000 ₽</strong>
          <Checkbox checked={balance === 50} onChange={() => setBalance(50)} />
        </label>
        <hr className={styles.Separator} />
        <label className={styles.TariffsItem}>
          <Flex gap={12} alignItems="center">
            <MaxTariff />
            <Flex flexDirection="column" gap={8} alignItems="flex-start">
              <p className={styles.TariffsItem__Title}>Maximum</p>
              <p className={styles.TariffsItem__Integrations}>100 интеграций</p>
            </Flex>
          </Flex>
          <strong className={styles.TariffsItem__Price}>30 000 ₽</strong>
          <Checkbox checked={balance === 100} onChange={() => setBalance(100)} />
        </label>
      </Flex>
      <CabinetActionButton className={styles.IntegrationsСard__button} onClick={handleSubmit}>
        Оплатить
      </CabinetActionButton>
    </Flex>
  )
}
