import React, { FC, useMemo, useState } from 'react'
import cn from 'classnames'

import { useBloggerProfile } from 'src/shared/model/useBloggerProfile'

import { ReactComponent as Check } from 'src/shared/assets/check.svg'
import { ReactComponent as Cross } from 'src/shared/assets/close.svg'
import { ReactComponent as Hourglass } from 'src/shared/assets/hourglass.svg'
import { UserStatusType } from 'src/shared/config/types'

import { Flex } from 'src/shared/ui/Flex'
import { Tabs } from 'src/shared/ui/Tabs'

import { BloggerPersonalForm } from '../BloggerPersonalForm/BloggerPersonalForm'
import { BloggerProfileData } from '../BloggerProfileData/BloggerProfileData'
import { BloggerVerification } from '../BloggerVerification/BloggerVerification'

import styles from './BloggerProfile.module.scss'

enum TabType {
  PERSONAL_INFO_TAB_ID = 'personal',
  PROFILE_DATA = 'profile',
  VERIFICATION_TAB_ID = 'verification',
}

const statusConfig = {
  NOT_VERIFIED: {
    icon: Cross,
    cssClass: styles.StatusError,
    text: 'Вы не прошли верификацию аккаунта. Пожалуйста, сделайте это чтобы откликаться на заказы селлеров.',
  },
  PENDING_VERIFICATION: {
    icon: Hourglass,
    cssClass: styles.StatusWarning,
    text: 'Вы отправили данные на верификацию. Пожалуйста, дождитесь одобрения заявки.',
  },
  VERIFIED: {
    icon: Check,
    cssClass: styles.StatusSuccess,
    text: 'Верификация аккаунта пройдена.',
  },
  TEMPORARY_BLOCKED: {
    icon: Hourglass,
    cssClass: styles.StatusWarning,
    text: 'Ваш аккаунт ограничен, свяжитесь с техподдержкой',
  },
  NEEDS_REWORK: {
    icon: Cross,
    cssClass: styles.StatusError,
    text: 'Ваша анкета отправлена на доработку. Пожалуйста, внесите необходимые изменения и повторно отправьте анкету на верификацию.',
  },
  REJECTED: {
    icon: Cross,
    cssClass: styles.StatusError,
    text: 'Ваша анкета отклонена. Для уточнения причины или помощи в исправлении обратитесь в службу поддержки.',
  },
}

export const BloggerProfile: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(TabType.PERSONAL_INFO_TAB_ID)
  const profile = useBloggerProfile()

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.PERSONAL_INFO_TAB_ID,
        title: 'Личная информация',
      },
      ...(profile?.status === UserStatusType.ACTIVATED
        ? [
            {
              tabId: TabType.PROFILE_DATA,
              title: 'Данные аккаунта',
            },
          ]
        : []),
      ...((profile?.status === UserStatusType.NOT_ACTIVATED || profile?.status === UserStatusType.INCOMPLETE_DATA) &&
      !profile?.isFullInfo
        ? [
            {
              tabId: TabType.VERIFICATION_TAB_ID,
              title: 'Верификация аккаунта',
            },
          ]
        : []),
    ],
    [profile],
  )

  const currentStatus = useMemo(() => {
    if (profile?.status === UserStatusType.NOT_ACTIVATED && !profile?.isFullInfo) {
      return statusConfig.NOT_VERIFIED
    }
    if (profile?.status === UserStatusType.NOT_ACTIVATED && profile?.isFullInfo) {
      return statusConfig.PENDING_VERIFICATION
    }
    if (profile?.status === UserStatusType.ACTIVATED) {
      return statusConfig.VERIFIED
    }
    if (profile?.status === UserStatusType.INCOMPLETE_DATA && !profile?.isFullInfo) {
      return statusConfig.NEEDS_REWORK
    }
    if (profile?.status === UserStatusType.CANCELED) {
      return statusConfig.REJECTED
    }
    if (profile?.status === UserStatusType.TEMPORARY_BLOCKED) {
      return statusConfig.TEMPORARY_BLOCKED
    }
    return null
  }, [profile])

  return (
    <div>
      <Tabs className={styles.Tabs} tabs={tabs} activeTabId={activeTabId} onTabClick={setActiveTabId} />

      {currentStatus && (
        <Flex gap={16} className={cn(styles.Status, currentStatus.cssClass)}>
          <div className={styles.StatusIcon}>
            <currentStatus.icon />
          </div>
          <div className={styles.StatusText}>{currentStatus.text}</div>
        </Flex>
      )}

      {activeTabId === TabType.PERSONAL_INFO_TAB_ID && (
        <BloggerPersonalForm profile={profile} key={`profile-${profile?.id}`} />
      )}
      {activeTabId === TabType.VERIFICATION_TAB_ID && (
        <BloggerVerification profile={profile} key={`profile-${profile?.id}`} setActiveTabId={setActiveTabId} />
      )}
      {activeTabId === TabType.PROFILE_DATA && profile?.status === UserStatusType.ACTIVATED && (
        <BloggerProfileData profile={profile} key={`profile-${profile?.id}`} />
      )}
    </div>
  )
}
