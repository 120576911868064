import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex'
import { Modal } from 'src/shared/ui/Modal'

import styles from './CommentIntegrationModal.module.scss'

interface CommentIntegrationModalProps {
  onClose(): void
  comment: string
}

export const CommentIntegrationModal: FC<CommentIntegrationModalProps> = ({ comment, onClose }) => {
  return (
    <Modal className={styles.Modal} title="Комментарий от блогера" size="small" onClose={onClose}>
      <Flex flexDirection="column" gap={8} className={styles.Comments}>
        <div className={styles.Comment}>{comment}</div>
      </Flex>
    </Modal>
  )
}
