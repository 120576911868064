import { useCallback } from 'react'

import { UserStatusType } from 'src/shared/config/types'

import { updateChangeRequestStatusType, updateUserStatusType } from '../ui/AdminCabinetUsers/AdminCabinetUsers'

interface useUpdateUserProps {
  updateUser: (params: updateUserStatusType) => void
  updateChangeRequestStatus: (params: updateChangeRequestStatusType) => void
}

export const useUpdateUser = ({ updateUser, updateChangeRequestStatus }: useUpdateUserProps) => {
  const activateUser = useCallback(
    (userId: number) => {
      const message = {
        loading: 'Подтверждаем пользователя',
        success: 'Пользователь подтверждён! ✨',
        error: 'Ошибка при подтверждении пользователя',
      }

      updateUser({ userId, data: { status: UserStatusType.ACTIVATED }, message })
    },
    [updateUser],
  )

  const sendUserForImprovement = useCallback(
    (userId: number, comment: string) => {
      const message = {
        loading: 'Отправляем пользователя на доработку',
        success: 'Пользователь отправлен на доработку!',
        error: 'Ошибка при отправке пользователя на доработку',
      }

      updateUser({ userId, data: { status: UserStatusType.INCOMPLETE_DATA, comment }, message })
    },
    [updateUser],
  )

  const rejectUser = useCallback(
    (userId: number, comment: string) => {
      const message = {
        loading: 'Отправляем в черный список',
        success: 'Пользователь в черном списке!',
        error: 'Ошибка при отправке в черный список',
      }

      updateUser({ userId, data: { status: UserStatusType.CANCELED, comment }, message })
    },
    [updateUser],
  )

  const blockUser = useCallback(
    (userId: number) => {
      const message = {
        loading: 'Отправляем в черный список',
        success: 'Пользователь в черном списке!',
        error: 'Ошибка при отправке в черный список',
      }

      updateUser({ userId, data: { status: UserStatusType.CANCELED }, message })
    },
    [updateUser],
  )

  const unblockUser = useCallback(
    (userId: number) => {
      const message = {
        loading: 'Разблокируем пользователя',
        success: 'Пользователь разаблокирован!',
        error: 'Ошибка при разблокировке',
      }

      updateUser({ userId, data: { status: UserStatusType.ACTIVATED }, message })
    },
    [updateUser],
  )

  const confirmChangeRequest = useCallback(
    (userId: number) => {
      const message = {
        loading: 'Одобряем заявку на изменение',
        success: 'Заявка на изменение одобрена! ✨',
        error: 'Ошибка при одобрении заявки на изменении',
      }

      updateChangeRequestStatus({ userId, status: 'CONFIRMED', message })
    },
    [updateChangeRequestStatus],
  )

  const rejectChangeRequest = useCallback(
    (userId: number) => {
      const message = {
        loading: 'Отклоняем заявку на изменение',
        success: 'Заявка на изменение отклонена!',
        error: 'Ошибка при отклонении заявки на изменении',
      }

      updateChangeRequestStatus({ userId, status: 'REJECTED', message })
    },
    [updateChangeRequestStatus],
  )

  return {
    activateUser,
    sendUserForImprovement,
    rejectUser,
    blockUser,
    unblockUser,
    confirmChangeRequest,
    rejectChangeRequest,
  }
}
