import { useCallback, useEffect } from 'react'
import { throttle } from 'lodash'

type UseInfiniteScrollParams = {
  fetchNextPage: () => void
  hasNextPage: boolean | undefined
  isFetchingNextPage: boolean
}

export const useInfiniteScroll = ({ fetchNextPage, hasNextPage, isFetchingNextPage }: UseInfiniteScrollParams) => {
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY
    const viewportHeight = window.innerHeight
    const documentHeight = document.body.scrollHeight
    const shouldFetch = hasNextPage && !isFetchingNextPage && scrollTop + viewportHeight >= documentHeight - 200

    if (shouldFetch) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const throttledHandleScroll = useCallback(throttle(handleScroll, 200), [handleScroll])

  useEffect(() => {
    if (throttledHandleScroll) {
      window.addEventListener('scroll', throttledHandleScroll)

      return () => {
        window.removeEventListener('scroll', throttledHandleScroll)
      }
    }
  }, [throttledHandleScroll])
}
