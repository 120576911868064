import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { useSocket } from 'src/app/lib/SocketProvider'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { groupNotificationsByTime } from '../../lib/groupNotificationsByTime'
import { Notification, NotificationType } from '../Notification/Notification'

import styles from './CabinetNotifications.module.scss'

export const CabinetNotifications: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const [notifications, setNotifications] = useState<NotificationType[]>([])

  const socket = useSocket()

  useEffect(() => {
    if (socket) {
      socket.emit('notification:get')

      socket.on('notification:update', (data: NotificationType[]) => {
        setNotifications(data)
      })
    }

    return () => {
      socket?.off('notification:update')
    }
  }, [socket])

  const platformNotifications = notifications.filter((notification) => notification.notificationType === 'PLATFORM')
  const technicalNotifications = notifications.filter((notification) => notification.notificationType === 'TECHNICAL')
  const groupedTechnicalNotifications = groupNotificationsByTime(technicalNotifications)

  return (
    <CabinetPageLayout className={styles.Layout}>
      <header className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Уведомления</PageTitle>
      </header>

      <div className={styles.Main}>
        {/* PLATFORM уведомления */}
        <section className={styles.Group}>
          {platformNotifications.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        </section>

        {/* TECHNICAL уведомления с разделением по времени */}
        {Object.entries(groupedTechnicalNotifications).map(([timeGroup, notifications]) => (
          <section className={styles.Group} key={timeGroup}>
            <h3 className={styles.GroupTitle}>{timeGroup}</h3>
            {notifications.map((notification) => (
              <Notification key={notification.id} {...notification} />
            ))}
          </section>
        ))}
      </div>
    </CabinetPageLayout>
  )
}
