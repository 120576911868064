import React, { FC, useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { useCustomerProfile } from 'src/shared/model/useCustomerProfile'
import { useUserInfo } from 'src/shared/model/useUser'

import {
  CABINET_MARKETPLACES_ROUTE,
  CABINET_NOTIFICATIONS,
  CABINET_PAYMENT_ROUTE,
  CABINET_PRODUCTS_ROUTE,
  CABINET_PROFILE_ROUTE,
  CABINET_SETTINGS,
  CABINET_TASKS_ROUTE,
  CABINET_TOPICS_ROUTE,
  CABINET_USERS_ROUTE,
} from 'src/shared/config/consts'
import { RoleType } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { Avatar } from 'src/shared/ui/Avatar'
import { Icon } from 'src/shared/ui/Icon'

import { Switch } from 'src/widgets/Switch'

import { IconButton } from '../IconButton/IconButton'
import { Logo } from '../Logo/Logo'
import { MenuContent } from '../MenuContent/MenuContent'
import { SupportLink } from '../SupportLink/SupportLink'
import { TelegramLink } from '../TelegramLink/TelegramLink'

import styles from './Header.module.scss'

export const Header: FC = () => {
  const { user } = useUserInfo()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const customerProfile = useCustomerProfile()
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const handleMenuClose = useCallback(() => setIsMenuVisible(false), [])
  const handleProfileClick = useCallback(() => {
    if (isMenuVisible) {
      handleMenuClose()
    }
    navigate(CABINET_PROFILE_ROUTE)
  }, [navigate, isMenuVisible])
  const handleNotificationsClick = useCallback(() => navigate(CABINET_NOTIFICATIONS), [navigate])
  const handleSettingsClick = useCallback(() => navigate(CABINET_SETTINGS), [navigate])
  const toggleMenuIconClick = useCallback(() => setIsMenuVisible((prev) => !prev), [])

  const navigationItems = useMemo(() => {
    if (user?.role === RoleType.CUSTOMER) {
      return [
        {
          title: 'Мои товары',
          to: CABINET_PRODUCTS_ROUTE,
        },
        {
          title: 'Мои задания',
          to: CABINET_TASKS_ROUTE,
        },
      ]
    }

    if (user?.role === RoleType.BLOGER) {
      return [
        {
          title: 'Мои задания',
          to: CABINET_TASKS_ROUTE,
        },
      ]
    }

    if (user?.role === RoleType.SUPER_ADMIN) {
      return [
        {
          title: 'Анкеты',
          to: CABINET_USERS_ROUTE,
        },
        {
          title: 'Товары',
          to: CABINET_PRODUCTS_ROUTE,
        },
        {
          title: 'Задания',
          to: CABINET_TASKS_ROUTE,
        },
        {
          title: 'Топики товаров',
          to: CABINET_TOPICS_ROUTE,
        },
        {
          title: 'Маркетплейсы',
          to: CABINET_MARKETPLACES_ROUTE,
        },
      ]
    }

    return []
  }, [user])

  return (
    <div className={styles.Header}>
      <Logo onClick={handleMenuClose} />
      <div className={styles.Navigation}>
        {navigationItems.map(({ title, to }) => {
          const isActive = pathname === to
          return (
            <Link key={to} className={cn(styles.NavigationItem, isActive && styles.NavigationItem_active)} to={to}>
              {title}
            </Link>
          )
        })}
        {user?.role === RoleType.CUSTOMER && customerProfile && (
          <Link to={CABINET_PAYMENT_ROUTE} className={styles.NavigationItem}>
            {`Мой баланс: ${customerProfile.total || 0} ${plural(['интеграция', 'интеграции', 'интеграций'], customerProfile.total || 0)}`}
          </Link>
        )}
      </div>
      <div className={styles.Menu}>
        <Switch CustomerComponent={TelegramLink} BloggerComponent={SupportLink} />

        <IconButton icon="settings" onClick={handleSettingsClick} />
        <IconButton icon="bell" onClick={handleNotificationsClick} />
      </div>
      {user && (
        <Avatar
          className={styles.Avatar}
          avatar={user.avatar}
          firstName={user.firstName}
          onClick={handleProfileClick}
        />
      )}
      <Icon className={styles.MenuIcon} name="menu" onClick={toggleMenuIconClick} />
      {isMenuVisible && <MenuContent navigationItems={navigationItems} onClose={handleMenuClose} />}
    </div>
  )
}
