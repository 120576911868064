import React, { FC, useMemo } from 'react'
import cn from 'classnames'

import { CollectionType, OrderStatusType, RoleType } from 'src/shared/config/types'

import { Flex } from '../Flex'

import styles from './OrderStatus.module.scss'

interface OrderStatusPropsType {
  className?: string
  status: string
  collection: string
  confirmedCount: number
  maxParticipants: number
  role?: RoleType
}

export const OrderStatus: FC<OrderStatusPropsType> = ({
  className,
  status,
  collection,
  confirmedCount,
  maxParticipants,
  role,
}) => {
  const text = useMemo(() => {
    switch (status) {
      case OrderStatusType.CANCELED:
        return role && role === RoleType.BLOGER ? 'Селлер отказал вам' : 'Отменен'
      case OrderStatusType.COMPLETED:
        return 'Завершен'
      case OrderStatusType.PAUSE:
        return 'Сбор приостановлен'
      case OrderStatusType.IN_PROGRESS:
        return collection === CollectionType.AUTOMATIC
          ? 'Нужное количество собрано, задание активно'
          : 'Задание активно'
      case OrderStatusType.COLLECTING:
        return 'Подождите, пока соберутся блогеры'
    }
  }, [status, collection, role])

  const isCanceled = status === OrderStatusType.CANCELED

  const isPending = status === OrderStatusType.COLLECTING

  return (
    <Flex className={cn(styles.OrderStatus, className)} alignItems="center">
      <span
        className={cn(styles.Status, isCanceled && styles.Status__isCanceled, isPending && styles.Status__isPending)}
      >
        {text}
      </span>
      {collection === CollectionType.AUTOMATIC &&
        status !== OrderStatusType.CANCELED &&
        status !== OrderStatusType.COMPLETED && (
          <span className={cn(styles.Progress, isPending && styles.Progress__isPending)}>
            {`${confirmedCount} / ${maxParticipants}`}
          </span>
        )}
    </Flex>
  )
}
