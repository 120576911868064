import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, OrderManagementDto } from 'src/shared/api'

export const useChangeOrderStatusMutation = () => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { id: number; variables: OrderManagementDto }
  >({
    mutationFn: ({ id, variables }) => AdminAPI.api.ordersControllerChangeOrderStatus(id, variables),
  })
}
