import React, { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import cn from 'classnames'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import {
  AdminAPI,
  CommonMessageResponse,
  ExcelReportResponseDto,
  HttpResponse,
  MyOrderListByCustomerResponseDto,
} from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomerOrderListResponseDto } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { Tabs } from 'src/shared/ui/Tabs'
import { Task } from 'src/shared/ui/Task'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { Search } from 'src/widgets/Search'

import styles from './AdminCabinetTasks.module.scss'

type OrdersResponse = {
  count: number
  total: number
  data?: MyOrderListByCustomerResponseDto[]
}

enum TabType {
  MANUAL_TASKS = 'MANUAL',
  AUTOMATIC_TASKS = 'AUTOMATIC',
  COLLECTING_TASKS = 'COLLECTING',
  COMPLETED_TASKS = 'COMPLETED',
}

export const AdminCabinetTasks: FC = () => {
  const [search, setSearchValue] = useState('')

  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTabId = searchParams.get('tab') || TabType.MANUAL_TASKS

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.MANUAL_TASKS,
        title: 'Ручной сбор',
      },
      {
        tabId: TabType.AUTOMATIC_TASKS,
        title: 'Автоматический сбор',
      },
      {
        tabId: TabType.COLLECTING_TASKS,
        title: 'На сборе',
      },
      {
        tabId: TabType.COMPLETED_TASKS,
        title: 'Завершённые',
      },
    ],
    [],
  )

  const isCollectingTab = activeTabId === TabType.MANUAL_TASKS || activeTabId === TabType.AUTOMATIC_TASKS

  const {
    data: ordersData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<OrdersResponse, Error>({
    queryKey: ['orders', activeTabId, search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.managementControllerGetAllOrders({
        page: Number(pageParam),
        take: 20,
        collection: isCollectingTab ? activeTabId : undefined,
        status:
          activeTabId === TabType.COLLECTING_TASKS || activeTabId === TabType.COMPLETED_TASKS ? activeTabId : undefined,
        search,
        _createAt: 'desc',
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages.length
      const totalPages = Math.ceil(lastPage.total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  const orders = useMemo(
    () => ordersData?.pages.flatMap((page) => page.data),
    [ordersData],
  ) as unknown as CustomerOrderListResponseDto[]

  const downloadExcelMutation = useMutation<
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>,
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>
  >({
    mutationFn: () => AdminAPI.api.managementControllerGenerateOrdersExcel(),
  })

  const handleDownloadExcel = useCallback(() => {
    const updateCustomerToastId = toast.loading('Подготавливаем Excel файл')

    downloadExcelMutation.mutate(undefined, {
      onSuccess: (data) => {
        const link = document.createElement('a')
        link.href = data.data.link
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.success('Файл скачан! ✨', {
          id: updateCustomerToastId,
        })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при создании файла', {
          id: updateCustomerToastId,
        })
      },
    })
  }, [])

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  const handleTabClick = useCallback(
    (newTabId: TabType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const notifyLaunchMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    number
  >({
    mutationFn: (orderId) => AdminAPI.api.managementControllerNotifyByOrderId(orderId),
  })

  const handleNotifyLaunch = (id: number) => {
    const notifyLaunchToastId = toast.loading('Отправляем рассылку')

    notifyLaunchMutation.mutate(id, {
      onSuccess: () => {
        toast.success('Рассылка отправлена! ✨', {
          id: notifyLaunchToastId,
        })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при отправке рассылки', {
          id: notifyLaunchToastId,
        })
      },
    })
  }

  return (
    <CabinetPageLayout>
      <Flex justifyContent="space-between" alignItems="center">
        <h1 className={styles.Title}>
          {`${ordersData?.pages[0].total || 0} ${plural(['задание', 'задания', 'заданий'], ordersData?.pages[0].total || 0)}`}
        </h1>
        <CabinetGhostButton icon="download" onClick={handleDownloadExcel}>
          Отчёт по заданиям
        </CabinetGhostButton>
      </Flex>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={handleTabClick} />
      <Search setSearchValue={setSearchValue} placeholder="Поиск заданий по номеру, названию товара или артикулу" />
      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders?.map((item) => (
          <Task key={item.id} task={item} onClick={openTask} onNotifyLaunch={handleNotifyLaunch} />
        ))}
      </Flex>

      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}
    </CabinetPageLayout>
  )
}
