import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex'

import styles from './BloggerCollection.module.scss'

export const BloggerCollection: FC = () => {
  return (
    <Flex flexDirection="column" className={styles.Collections}>
      <h2 className={styles.Collections__title}>Виды заказов для блогеров:</h2>
      <article className={styles.CollectionType}>
        <h3 className={styles.CollectionType__title}>Автоматический сбор</h3>
        <p className={styles.CollectionType__text}>
          Этот подход идеален для тех, кто стремится быстро найти большое количество потенциальных блогеров. Благодаря
          поисковому алгоритму нашего сайта, задания будут автоматически показываться соответствующим блогерам в
          зависимости от их интересов и категорий блога. Отклики будут распределяться среди тех, кто первым откликнется
          на задание. Этот метод эффективно работает как для создания регулярного контента во внешней рекламе, так и для
          внутренней рекламы.
        </p>
        <strong className={styles.CollectionType__price}>
          Цена 1 участника в автоматическом сборе –<span>1 интеграция</span>
        </strong>
      </article>
      <article className={styles.CollectionType}>
        <h3 className={styles.CollectionType__title}>Ручной сбор</h3>
        <p className={styles.CollectionType__text}>
          Ручной подход позволяет гораздо точнее подходить к отбору блогеров, учитывая специфические требования и
          критерии вашего задания. Вы сможете изучить целевую аудиторию блогера, его эстетику и другие публикации, чтобы
          удостовериться, что он идеально подходит для вашего задания. Ручной отбор также ориентирован на увеличение
          внешнего трафика и повышение видимости вашего контента.
        </p>
        <strong className={styles.CollectionType__price}>
          Цена 1 участника в ручном сборе –<span>3 интеграции</span>
        </strong>
      </article>
    </Flex>
  )
}
