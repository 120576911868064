import React, { ForwardedRef, forwardRef, ReactElement } from 'react'
import cn from 'classnames'

import { ReactComponent as BigStar } from 'src/shared/assets/big_star.svg'
import { CUSTOMER_REGISTRATION_ROUTE } from 'src/shared/config/consts'

import { LandingButton } from 'src/shared/ui/LandingButton'

import { Tariff } from '../Tariff/Tariff'

import styles from './Tariffs.module.scss'

interface TariffsPropsType {
  className: string
}

const TariffsWithRef = (
  { className }: TariffsPropsType,
  forwardedRef: ForwardedRef<HTMLDivElement>,
): ReactElement<TariffsPropsType> => {
  return (
    <section className={cn(styles.Tariffs, className)} ref={forwardedRef} aria-labelledby="tariffs-title">
      <div className={styles.TitleRow}>
        <h2 className={styles.Title} id="tariffs-title">
          Наши тарифы
        </h2>
        <LandingButton className={styles.LandingButton} to={CUSTOMER_REGISTRATION_ROUTE}>
          Зарегестрироваться
        </LandingButton>
      </div>
      <div className={styles.Container}>
        <Tariff
          title="Lite"
          integrations="20 интеграций"
          items={['Reels & stories у блогера', 'Отзыв с фото', 'Выкуп по ключевой фразе']}
          price="10 000₽"
        />
        <Tariff
          title="Optimal"
          integrations="50 интеграций"
          items={['Reels & stories у блогера', 'Отзыв с фото', 'Выкуп по ключевой фразе']}
          price="20 000₽"
        />
        <Tariff
          title="Maximum"
          integrations="100 интеграций"
          items={['Reels & stories у блогера', 'Отзыв с фото', 'Выкуп по ключевой фразе']}
          price="30 000₽"
        />
      </div>
      <div className={styles.CollectionTypes}>
        <article className={cn(styles.CollectionType, styles.AutoCollect)} aria-labelledby="auto-collect-title">
          <h3 id="auto-collect-title" className={cn(styles.CollectionType__title)}>
            Автоматический сбор блогеров
          </h3>
          <p className={styles.CollectionType__text}>
            Этот подход идеален для тех, кто стремится быстро найти большое количество потенциальных блогеров. Благодаря
            поисковому алгоритму нашего сайта, задания будут автоматически показываться соответствующим блогерам в
            зависимости от их интересов и категорий блога. Отклики будут распределяться среди тех, кто первым
            откликнется на задание. Этот метод эффективно работает как для создания регулярного контента во внешней
            рекламе, так и для внутренней рекламы.
          </p>
        </article>
        <article className={cn(styles.CollectionType, styles.ManualCollect)} aria-labelledby="manual-collect-title">
          <div className={styles.ManualCollect__header}>
            <BigStar />
            <h3 id="manual-collect-title" className={styles.CollectionType__title}>
              Ручной сбор блогеров
            </h3>
          </div>
          <p className={styles.CollectionType__text}>
            Ручной подход позволяет гораздо точнее подходить к отбору блогеров, учитывая специфические требования и
            критерии вашего задания. Вы сможете изучить целевую аудиторию блогера, его эстетику и другие публикации,
            чтобы удостовериться, что он идеально подходит для вашего задания. Ручной отбор также ориентирован на
            увеличение внешнего трафика и повышение видимости вашего контента.
          </p>
        </article>
      </div>
    </section>
  )
}

export const Tariffs = forwardRef(TariffsWithRef)
