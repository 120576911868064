import React, { FC } from 'react'
import cn from 'classnames'

import { ReactComponent as Checkbox } from 'src/shared/assets/checkbox.svg'

import styles from './Tariff.module.scss'

interface TariffPropsType {
  className?: string
  title: string
  integrations: string
  items: string[]
  price: string
}

export const Tariff: FC<TariffPropsType> = ({ className, title, integrations, items, price }) => {
  return (
    <article className={cn(styles.Tariff, className)} aria-labelledby={`${title}-title`}>
      <h3 id={`${title}-title`} className={styles.Title}>
        {title}
      </h3>
      <p className={styles.Integrations}>{integrations}</p>
      <ul className={styles.List}>
        {items.map((item) => (
          <li className={styles.Item} key={item}>
            <Checkbox className={styles.Checkbox} />
            {item}
          </li>
        ))}
      </ul>
      <strong className={styles.Price} aria-label="Цена" aria-describedby="sub-price">
        {price}
      </strong>
      {/* <p id="sub-price" className={styles.SubPrice}>
        За одну интеграцию
      </p> */}
    </article>
  )
}
