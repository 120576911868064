import React, { FC, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useUpload } from 'src/shared/model/useUpload'

import { AdminAPI, CommonMessageResponse, EditReportDto, HttpResponse } from 'src/shared/api'
import { CustomReporterType, OrderResponsesResponseDto } from 'src/shared/config/types'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CheckboxField } from 'src/shared/ui/CheckboxField'
import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { UploaderFormField } from 'src/shared/ui/UploaderFormField'

import styles from './AdminMediaSections.module.scss'

interface AdminMediaSectionsPropsType {
  orderResponse: OrderResponsesResponseDto
}

export type EditReportFields =
  | 'orderVideo'
  | 'orderScreenshot'
  | 'paymentScreenshot'
  | 'adDate'
  | 'screenshotMyPurchases'
  | 'screenshotShtrihcode'
  | 'realsLink'
  | 'reviewsScreenshot'
  | 'storiesScreenshot'
  | 'statisticsScreenshot'

const FORM_ID = 'MediaSectionForm'

export const AdminMediaSections: FC<AdminMediaSectionsPropsType> = ({ orderResponse }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [currentUploading, setCurrentUploading] = useState<string>()
  const { taskId, responseId } = useParams()
  const queryClient = useQueryClient()
  const upload = useUpload()

  const orderId = Number(taskId)
  const reportId = Number(responseId)

  const handleEditMode = () => {
    setIsEditMode((prev) => !prev)
  }

  const methods = useForm<EditReportDto>({
    defaultValues: {
      orderVideo: orderResponse.orderVideo,
      orderScreenshot: orderResponse.orderScreenshot,
      paymentScreenshot: orderResponse.paymentScreenshot,
      adDate: orderResponse.adDate,
      screenshotMyPurchases: orderResponse.screenshotMyPurchases,
      screenshotShtrihcode: orderResponse.screenshotShtrihcode,
      realsLink: orderResponse.realsLink,
      reviewsScreenshot: orderResponse.reviewsScreenshot,
      storiesScreenshot: orderResponse.storiesScreenshot,
      statisticsScreenshot: orderResponse.statisticsScreenshot,
    },
  })
  const { handleSubmit, watch, setValue } = methods

  const { mutate: editResponseMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    EditReportDto
  >({
    mutationFn: (data) => AdminAPI.api.managementControllerEditResponse(reportId, data),
  })

  const uploadMedia = async (file: File, id: EditReportFields) => {
    setCurrentUploading(id)
    const uploadMediaToastId = toast.loading('Загружаем файл')
    setValue(id, ' ')

    try {
      const uploadedFile = await upload(file)
      setValue(id, uploadedFile)

      toast.success('Файл загружен', {
        id: uploadMediaToastId,
      })
    } catch (error) {
      toast.error(String(error) || 'Ошибка при загрузке файла', {
        id: uploadMediaToastId,
      })
    } finally {
      setCurrentUploading(undefined)
    }
  }

  const isSecondSectionVisible = useMemo(
    () =>
      [CustomReporterType.COMPLETED_STEP_2, CustomReporterType.COMPLETED].includes(
        orderResponse.status as CustomReporterType,
      ),
    [],
  )

  const isThirdSectionVisible = useMemo(() => orderResponse.status === CustomReporterType.COMPLETED, [])

  const renderMediaItem = (id: EditReportFields, description: string, type: 'image' | 'video') => {
    if (!watch(id)) return null
    return isEditMode ? (
      <UploaderFormField
        key={id}
        id={id}
        name={id}
        type={type}
        kind="smallCabinet"
        placeholder="Загрузить файл"
        label={description}
        isLoading={currentUploading === id}
        className={styles.MediaUploadItem}
        disabled={Boolean(currentUploading)}
        onChange={(file: File) => uploadMedia(file, id)}
      />
    ) : (
      <Media key={id} type={type} src={watch(id)} desciption={description} />
    )
  }

  const onSubmit = (data: EditReportDto) => {
    const updateUserToastId = toast.loading('Обновляем данные интеграции')
    function getUpdatedValue<T>(defaultValue: T, newValue: T): T | undefined {
      return defaultValue === newValue ? undefined : newValue
    }

    const editResponseData: EditReportDto = {
      orderVideo: getUpdatedValue(orderResponse.orderVideo, data.orderVideo),
      orderScreenshot: getUpdatedValue(orderResponse.orderScreenshot, data.orderScreenshot),
      paymentScreenshot: getUpdatedValue(orderResponse.paymentScreenshot, data.paymentScreenshot),
      adDate: getUpdatedValue(orderResponse.adDate, data.adDate),
      screenshotMyPurchases: getUpdatedValue(orderResponse.screenshotMyPurchases, data.screenshotMyPurchases),
      screenshotShtrihcode: getUpdatedValue(orderResponse.screenshotShtrihcode, data.screenshotShtrihcode),
      realsLink: getUpdatedValue(orderResponse.realsLink, data.realsLink),
      reviewsScreenshot: getUpdatedValue(orderResponse.reviewsScreenshot, data.reviewsScreenshot),
      storiesScreenshot: getUpdatedValue(orderResponse.storiesScreenshot, data.storiesScreenshot),
      statisticsScreenshot: getUpdatedValue(orderResponse.statisticsScreenshot, data.statisticsScreenshot),
    }

    editResponseMutation(editResponseData, {
      onSuccess: () => {
        toast.success('Данные обновлены! ✨', {
          id: updateUserToastId,
        })
        queryClient.invalidateQueries({ queryKey: [['orders', orderId, reportId]] })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Ошибка при обновлении данных', {
          id: updateUserToastId,
        })
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" gap={32}>
          <Flex flexDirection="column" gap={24}>
            <Flex justifyContent="space-between">
              <h1 className={styles.Title}>1 раздел. Заказ</h1>
              <CheckboxField checked={isEditMode} withHiddenLabel onChange={handleEditMode} id="EditMedia">
                Редактирование
              </CheckboxField>
            </Flex>

            <div className={styles.List}>
              {renderMediaItem('orderVideo', 'Видео заказа', 'video')}
              {renderMediaItem('orderScreenshot', 'Скриншот заказа', 'image')}
              {renderMediaItem('paymentScreenshot', 'Скриншот оплаты', 'image')}
            </div>
          </Flex>
          {isSecondSectionVisible && (
            <Flex flexDirection="column" gap={24}>
              <h1 className={styles.Title}>2 раздел. Выкуп</h1>
              {isEditMode ? (
                <TextInputFormField
                  id="adDate"
                  name="adDate"
                  label="Дата выхода рекламы:"
                  kind="cabinet"
                  disabled={!isEditMode}
                />
              ) : (
                <Field id="realsLink" label="Дата выхода рекламы:" kind="cabinet">
                  <div className={styles.Text}>{orderResponse.adDate}</div>
                </Field>
              )}

              <div className={styles.List}>
                {renderMediaItem('screenshotMyPurchases', 'Скрин раздела покупок', 'image')}
                {renderMediaItem('screenshotShtrihcode', 'Фото разрезанного штрихкода', 'image')}
              </div>
            </Flex>
          )}
          {isThirdSectionVisible && (
            <Flex flexDirection="column" gap={24}>
              <h1 className={styles.Title}>3 раздел. Реклама</h1>

              {isEditMode ? (
                <TextInputFormField id="realsLink" name="realsLink" label="Ссылка на рилс:" kind="cabinet" />
              ) : (
                <Field id="realsLink" label="Ссылка на рилс:" kind="cabinet">
                  <a className={styles.Link} href={orderResponse.realsLink} target="_blank" rel="noreferrer">
                    {orderResponse.realsLink}
                  </a>
                </Field>
              )}
              <div className={styles.List}>
                {renderMediaItem('reviewsScreenshot', 'Скрин отзыва', 'image')}
                {renderMediaItem('storiesScreenshot', 'Скрин сторис', 'image')}
                {renderMediaItem('statisticsScreenshot', 'Скрин статистики на сторис', 'image')}
              </div>
            </Flex>
          )}
        </Flex>
        {isEditMode && (
          <CabinetActionButton className={styles.Button} disabled={Boolean(currentUploading) || isLoading}>
            Сохранить
          </CabinetActionButton>
        )}
      </form>
    </FormProvider>
  )
}
