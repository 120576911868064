import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useInfiniteQuery } from '@tanstack/react-query'
import cn from 'classnames'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import { AdminAPI, ProductResponseDto } from 'src/shared/api'
import { ReactComponent as File } from 'src/shared/assets/file_add.svg'
import { CABINET_PRODUCTS_NEW_ROUTE, CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { ProductStatusType } from 'src/shared/config/types'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Product } from 'src/shared/ui/Product'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { EmptyState } from 'src/widgets/EmptyState'
import { Search } from 'src/widgets/Search'

import styles from './CabinetProducts.module.scss'

export const CabinetProducts: FC = () => {
  const [search, setSearchValue] = useState('')

  const navigate = useNavigate()
  const handleAddProductClick = useCallback(() => navigate(CABINET_PRODUCTS_NEW_ROUTE), [])

  const {
    data: productsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['products', search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.productsControllerFindAll({
        page: Number(pageParam),
        take: 20,
        search,
        _createAt: 'desc',
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages?.length
      const totalPages = Math.ceil(lastPage.total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    retry: false,
    initialPageParam: 1,
  })

  const products = useMemo(
    () => productsData?.pages?.flatMap((page) => page.data || []) || [],
    [productsData],
  ) as ProductResponseDto[]

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  const hasProducts = products?.length >= 1

  if (!hasProducts && !isLoading) {
    return (
      <CabinetPageLayout>
        <EmptyState title="У вас сейчас нет товаров" icon={<File />}>
          <CabinetGhostButton onClick={handleAddProductClick} icon="plus" className={styles.AddButton}>
            Добавить товар
          </CabinetGhostButton>
        </EmptyState>
      </CabinetPageLayout>
    )
  }

  return (
    <CabinetPageLayout>
      <Flex className={styles.Header} justifyContent="space-between" alignItems="center">
        <PageTitle>Мои товары</PageTitle>
        <CabinetGhostButton onClick={handleAddProductClick} icon="plus">
          Добавить товар
        </CabinetGhostButton>
      </Flex>
      <Search setSearchValue={setSearchValue} placeholder="Поиск товаров по названию или артикулу" />
      <Flex className={styles.Products} flexWrap="wrap">
        {products.map(({ id, photoUrl, title, price, status }) => {
          return (
            <Product
              key={id}
              id={id}
              photoUrl={photoUrl}
              title={title}
              price={price}
              onClick={() => navigate(`${CABINET_PRODUCTS_ROUTE}/${id}`)}
              status={status as ProductStatusType | undefined}
            />
          )
        })}
      </Flex>
      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}
    </CabinetPageLayout>
  )
}
