import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './EmptyState.module.scss'

interface EmptyStateProps {
  title: string
  description?: string
  icon?: ReactNode
  children?: ReactNode
  className?: string
}

export const EmptyState: FC<EmptyStateProps> = ({ title, description, icon, children, className }) => {
  return (
    <div className={cn(styles.Container, className)}>
      {icon && <div className={styles.Icon}>{icon}</div>}
      <h2 className={styles.Title}>{title}</h2>
      {description && <p className={styles.Description}>{description}</p>}
      {children}
    </div>
  )
}
