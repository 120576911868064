import React, { type FC, useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { DefaultError, MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { useRootRoutes } from '../../model/useRootRoutes'

import './App.scss'

const handleError = (data: DefaultError) => {
  console.error(data)
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
  mutationCache: new MutationCache({
    onError: handleError,
  }),
  queryCache: new QueryCache({
    onError: handleError,
  }),
})

export const App: FC = () => {
  const routes = useRootRoutes()
  const router = useMemo(() => createBrowserRouter(routes), [routes])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
