import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'

import { bonusPriceRules } from 'src/pages/CabinetTasks/lib/bonusPriceRules'
import { AdminAPI, CommonMessageResponse, CreateOrderDto, HttpResponse } from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CollectionType } from 'src/shared/config/types'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { CheckboxField } from 'src/shared/ui/CheckboxField'
import { CheckboxFormField } from 'src/shared/ui/CheckboxFormField'
import { Flex } from 'src/shared/ui/Flex'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { TextareaFormField } from 'src/shared/ui/TextareaFormField'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { CollectionSelectFormField } from '../CollectionSelectFormField/CollectionSelectFormField'
import { ProductSelectFormField } from '../ProductSelectFormField'

import styles from './CabinetTasksNew.module.scss'

type CabinetTasksNewFormFields = CreateOrderDto

export const CabinetTasksNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_TASKS_ROUTE), [navigate])

  const [needBonusPrice, setNeedBonusPrice] = useState(false)

  const methods = useForm<CabinetTasksNewFormFields>({
    defaultValues: {
      reParticipation: true,
      rePromotion: true,
    },
  })
  const { handleSubmit, watch } = methods
  const values = watch()

  const createOrderMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateOrderDto
  >({
    mutationFn: AdminAPI.api.ordersControllerCreate,
  })
  const isLoading = createOrderMutation.isPending

  const onSubmit = useCallback(
    (data: CabinetTasksNewFormFields) => {
      const createOrderDto: CreateOrderDto = {
        collection: data.collection,
        maxParticipants: Number(data.maxParticipants),
        commentMarketplace: data.commentMarketplace,
        commentIntegration: data.commentIntegration,
        keyWord: data.keyWord,
        rePromotion: data.rePromotion,
        reParticipation: true,
        productId: Number(data.productId),
        bonusPrice: needBonusPrice ? Number(data.bonusPrice) : undefined,
      }
      const createOrderToastId = toast.loading('Проверяем данные')

      if (createOrderDto.collection === CollectionType.MANUAL) {
        delete createOrderDto.maxParticipants
      }

      createOrderMutation.mutate(createOrderDto, {
        onSuccess: () => {
          toast.success('Задание успешно создано!', { id: createOrderToastId })
          navigate(CABINET_TASKS_ROUTE, { replace: true })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при создании задания', { id: createOrderToastId })
        },
      })
    },
    [createOrderMutation, needBonusPrice, navigate],
  )

  const isButtonDisabled = useMemo(
    () =>
      !values.productId ||
      (values.collection === CollectionType.AUTOMATIC ? !values.maxParticipants : false) ||
      !values.collection ||
      !values.keyWord ||
      isLoading,
    [values, isLoading],
  )

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Создание задания</PageTitle>
      </Flex>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <Flex className={styles.InputsContainers} flexDirection="column">
            <Flex className={styles.InputsContainer} flexDirection="column">
              <ProductSelectFormField
                id="productId"
                name="productId"
                label="Выберите товар"
                placeholder="Выбрать товар из списка"
              />
              <CollectionSelectFormField
                id="collection"
                name="collection"
                label="Выберите тип сбора"
                placeholder="Выберите тип сбора"
              />
              {values.collection === CollectionType.AUTOMATIC && (
                <NumericInputFormField
                  id="maxParticipants"
                  name="maxParticipants"
                  label="Укажите количество участников"
                  placeholder="Количество участников"
                  kind="cabinet"
                />
              )}
              <div className={styles.Checkboxes}>
                <CheckboxFormField
                  id="rePromotion"
                  name="rePromotion"
                  description="Разрешить участие блогерам, которые ранее участвовали в ваших заказах с этим артикулом товара"
                  withHiddenLabel
                >
                  Разрешить повторное участие
                </CheckboxFormField>
                {/* <CheckboxFormField
                  id="reParticipation"
                  name="reParticipation"
                  description="Разрешить участие блогерам, которые ранее участвовали в ваших заказах с этим артикулом товара"
                  withHiddenLabel
                >
                  Разрешить повторное участие
                </CheckboxFormField> */}
                <CheckboxField
                  id="needPrice"
                  name="needPrice"
                  description="Эта функция позволяет указать дополнительное вознаграждение для блогеров, участвующих в вашем заказе (100₽ - 10 000₽)."
                  withHiddenLabel
                  onChange={(event) => setNeedBonusPrice(event.target.checked)}
                  checked={needBonusPrice}
                >
                  Добавить дополнительную выплату блогеру
                </CheckboxField>
                {needBonusPrice && (
                  <NumericInputFormField
                    id="bonusPrice"
                    name="bonusPrice"
                    kind="cabinet"
                    unit="₽"
                    className={styles.BonusPrice}
                    defaultValue={100}
                    placeholder="Укажите дополнительную выплату"
                    rules={bonusPriceRules}
                  />
                )}
              </div>
            </Flex>
            <Flex className={styles.InputsContainer} flexDirection="column">
              <TextareaFormField
                id="keyWord"
                name="keyWord"
                label="Ключевое слово для выкупа товара"
                placeholder="Укажите ключевое слово"
                kind="cabinet"
              />
              <TextareaFormField
                id="commentMarketplace"
                name="commentMarketplace"
                label="Требования к отзыву на маркетплейсе"
                placeholder="Опишите требования"
                kind="cabinet"
              />
              <Flex flexDirection="column" gap={8}>
                <TextareaFormField
                  id="commentIntegration"
                  name="commentIntegration"
                  label="Требования к рекламной интеграции"
                  placeholder="Опишите требования"
                  kind="cabinet"
                />
                <span className={styles.Explanation}>❗Важно: не пишите артикул вашего товара в цифрах</span>
              </Flex>
            </Flex>
          </Flex>
          <CabinetButton className={styles.CabinetButton} disabled={isButtonDisabled}>
            Создать заказ
          </CabinetButton>
        </form>
      </FormProvider>
    </CabinetPageLayout>
  )
}
