import React, { FC, MouseEvent, useCallback, useState } from 'react'
import cn from 'classnames'

import { useUserInfo } from 'src/shared/model/useUser'

import { ReactComponent as ArrowDown } from 'src/shared/assets/arrow_down.svg'
import { ReactComponent as Bell } from 'src/shared/assets/bell.svg'
import { ReactComponent as Warning } from 'src/shared/assets/warning.svg'
import { CollectionType, CustomerOrderListResponseDto, OrderStatusType, RoleType } from 'src/shared/config/types'

import { getMarketplaceClass } from 'src/shared/lib/getMarketplaceClass'

import { Collection } from 'src/shared/ui/Collection'
import { Flex } from 'src/shared/ui/Flex'
import { OrderStatus } from 'src/shared/ui/OrderStatus'
import { СollectionButton } from 'src/shared/ui/СollectionButton'

import { CabinetActionButton } from '../CabinetActionButton'
import { Icon } from '../Icon'
import { Modal } from '../Modal'

import styles from './Task.module.scss'

interface AdminOrderListResponseDto extends CustomerOrderListResponseDto {
  tgUsername?: string
  firstName?: string
  isEarlyLaunch?: boolean
  isComment?: boolean
}

interface TaskPropsType {
  task: AdminOrderListResponseDto
  onClick(id: number): void
  onStatusChange?(id: number, status: OrderStatusType): void
  onCancelOrder?(id: number): void
  onEarlyLaunch?(id: number): void
  onNotifyLaunch?(id: number): void
}

interface SubmitModalPropsType {
  title: string
  description: string
  kind: 'primary' | 'ghost' | 'danger'
  submitText: string
  cancelText: string
  onSumbit: (event: MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
}

const SubmitModal: FC<SubmitModalPropsType> = ({
  title,
  kind,
  submitText,
  cancelText,
  description,
  onSumbit,
  onClose,
}) => {
  return (
    <Modal className={styles.RejectModal} title={title} size="small" onClose={onClose}>
      <div className={styles.ModalDescription}>{description}</div>
      <Flex className={styles.ModalButtons} flexDirection="column" alignSelf="center" gap={8}>
        <CabinetActionButton kind={kind} onClick={onSumbit} className={styles.ModalSubmit}>
          {submitText}
        </CabinetActionButton>
        <CabinetActionButton kind="ghost" onClick={onClose}>
          {cancelText}
        </CabinetActionButton>
      </Flex>
    </Modal>
  )
}

export const Task: FC<TaskPropsType> = ({
  task,
  onClick,
  onStatusChange,
  onCancelOrder,
  onEarlyLaunch,
  onNotifyLaunch,
}) => {
  const { user } = useUserInfo()
  const { photoUrl, title, collection, marketplaceName, status, maxParticipants, customReportersStatistic } = task
  const [isModalOpen, setOpenModal] = useState<'CancelOrder' | 'EarlyLaunch' | 'NotifyOrder'>()
  const [isTaskOpen, setOpenTask] = useState(false)

  const handleClick = useCallback(() => {
    if (isModalOpen) {
      return
    }
    onClick(task.id)
  }, [task, isModalOpen, onClick])

  const handleOpenStatus = useCallback((event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    event.stopPropagation()
    if (window.innerWidth <= 1024) {
      setOpenTask((prev) => !prev)
    } else {
      handleClick()
    }
  }, [])

  const handleCollectionButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onStatusChange?.(task.id, status === OrderStatusType.PAUSE ? OrderStatusType.IN_PROGRESS : OrderStatusType.PAUSE)
    },
    [task, onStatusChange],
  )

  const handleCancelOrdernButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setOpenModal('CancelOrder')
    },
    [setOpenModal],
  )

  const handleEarlyLaunchButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setOpenModal('EarlyLaunch')
    },
    [setOpenModal],
  )

  const handleNotifyLaunchButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setOpenModal('NotifyOrder')
    },
    [setOpenModal],
  )

  const renderItem = useCallback(
    (title: string, count: number, className: string, isWaitingForPayment = false) => (
      <Flex flexDirection="column" gap={8} className={cn(styles.CustomReportersItem, className)}>
        <span className={styles.CustomReportersTitle}>{title}</span>
        <span
          className={cn(
            styles.CustomReportersCount,
            isWaitingForPayment && styles.CustomReportersCount__isWaitingForPayment,
          )}
        >
          {count}
        </span>
      </Flex>
    ),
    [],
  )

  if (!customReportersStatistic) {
    return null
  }

  const confirmedCount =
    customReportersStatistic.COMPLETED +
    customReportersStatistic.RESET +
    customReportersStatistic.COMPLETED_STEP_2 +
    customReportersStatistic.CONFIRMED +
    customReportersStatistic.PAID +
    customReportersStatistic.PENDING +
    customReportersStatistic.WAIT_PAYMENT +
    customReportersStatistic.WAIT_PAY_CONFIRM

  const formattedUsername = task.tgUsername?.startsWith('@') ? task.tgUsername.slice(1) : task.tgUsername

  return (
    <Flex className={styles.Task} flexDirection="column" onClick={handleClick}>
      <div className={styles.Id}>{task.id}</div>

      <Flex className={styles.Header}>
        <Flex className={styles.Main} alignItems="center">
          <img className={styles.Photo} src={photoUrl} />
          <Flex flexDirection="column" gap={8}>
            <span className={styles.Title}>{title}</span>
            <Flex alignItems="baseline" flexWrap="wrap" gap={16}>
              <span className={cn(styles.Marketplace, getMarketplaceClass(marketplaceName))}>{marketplaceName}</span>
              <Flex flexWrap="wrap" className={styles.CollectionWrap}>
                <Collection collection={collection!} />
                {task.isEarlyLaunch && <span className={styles.EarlyLaunch}>«Досрочный запуск»</span>}
              </Flex>
            </Flex>
            {(task.firstName || task.tgUsername) && (
              <Flex gap={8} className={styles.Socials}>
                <Flex className={styles.PersonalInfo}>
                  <span className={styles.PersonalInfoTitle}>Селлер:</span>
                  <span className={styles.PersonalInfoValue}>{task.firstName || ''}</span>
                </Flex>
                <Flex className={styles.PersonalInfo}>
                  <span className={styles.PersonalInfoTitle}>Telegram:</span>
                  {task.tgUsername ? (
                    <a
                      href={`https://t.me/${formattedUsername}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={cn(styles.PersonalInfoValue, styles.PersonalInfoLink)}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {'@' + task.tgUsername}
                    </a>
                  ) : (
                    <span className={styles.PersonalInfoValue}>Нет</span>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex className={styles.BonusInfo}>
          <OrderStatus
            className={styles.OrderStatus}
            status={status!}
            collection={collection!}
            confirmedCount={confirmedCount}
            maxParticipants={maxParticipants!}
          />
          {task.bonusPrice && (
            <div className={styles.BonusPrice}>
              Доплата:
              <strong>+ {task.bonusPrice} ₽</strong>
            </div>
          )}
        </Flex>
      </Flex>
      <Flex
        className={cn(
          styles.Body,
          isTaskOpen && styles.BodyOpen,
          collection === CollectionType.MANUAL ? styles.BodyManual : styles.BodyAutomatic,
        )}
        gap={20}
        onClick={handleOpenStatus}
      >
        <ArrowDown className={styles.ArrowDown} />
        <Flex className={styles.CustomReporters} flexWrap="wrap">
          {collection === CollectionType.MANUAL && (
            <>
              {renderItem('Отклонены:', customReportersStatistic!.REJECTED, styles.CustomReportersRejected)}
              {renderItem('Откликнулись:', customReportersStatistic!.PENDING, styles.CustomReportersPending)}
            </>
          )}
          {renderItem(
            'Оформляют заказ:',
            customReportersStatistic!.CONFIRMED + customReportersStatistic!.RESET,
            styles.CustomReportersConfirmed,
          )}
          {renderItem('Ждут оплаты:', customReportersStatistic!.WAIT_PAYMENT, styles.CustomReportersWaitPayment, true)}
          {renderItem(
            'Оплата отправлена:',
            customReportersStatistic!.WAIT_PAY_CONFIRM,
            styles.CustomReportersWaitPaymentConfirm,
          )}
          {renderItem(
            'Выполняют задание:',
            customReportersStatistic!.PAID + customReportersStatistic!.COMPLETED_STEP_2,
            styles.CustomReportersInProgress,
          )}
          {renderItem('Сдали отчет:', customReportersStatistic!.COMPLETED, styles.CustomReportersCompleted)}
        </Flex>
        {onStatusChange &&
          collection === CollectionType.MANUAL &&
          (status === OrderStatusType.PAUSE || status === OrderStatusType.IN_PROGRESS) && (
            <СollectionButton
              className={styles.СollectionButton}
              status={status}
              onClick={handleCollectionButtonClick}
            />
          )}

        {user?.role === RoleType.CUSTOMER &&
          collection === CollectionType.AUTOMATIC &&
          status === OrderStatusType.COLLECTING && (
            <Flex gap={8} flexWrap="wrap" alignSelf="flex-end" className={styles.AutomaticBtns}>
              {onCancelOrder && (
                <button className={cn(styles.AutomaticBtn, styles.Cancel)} onClick={handleCancelOrdernButtonClick}>
                  Отмена задания
                  <Icon name="close" />
                </button>
              )}
              {onEarlyLaunch && (
                <button className={cn(styles.AutomaticBtn, styles.EarlyStart)} onClick={handleEarlyLaunchButtonClick}>
                  Досрочный запуск
                  <Icon name="start" />
                </button>
              )}
            </Flex>
          )}
      </Flex>
      <div className={styles.Controls}>
        {task.isComment && <Warning className={styles.Warning} />}
        {user?.role === RoleType.SUPER_ADMIN && (
          <button className={styles.Bell} onClick={handleNotifyLaunchButtonClick}>
            <Bell />
          </button>
        )}
      </div>

      {isModalOpen === 'CancelOrder' && (
        <SubmitModal
          title="Вы уверены, что хотите отменить задание?"
          description="При отмене задания оно полностью удаляется, и восстановить данные с откликами будет невозможно."
          kind="danger"
          submitText="Отменить"
          cancelText="Нет, вернуться назад"
          onSumbit={() => {
            setOpenModal(undefined)
            onCancelOrder?.(task.id)
          }}
          onClose={() => setOpenModal(undefined)}
        />
      )}
      {isModalOpen === 'EarlyLaunch' && (
        <SubmitModal
          title="Вы уверены, что хотите запустить досрочно задание?"
          description="При досрочном запуске задания новые отклики на него больше не поступят."
          kind="primary"
          submitText="Запустить"
          cancelText="Нет, вернуться назад"
          onSumbit={() => {
            setOpenModal(undefined)
            onEarlyLaunch?.(task.id)
          }}
          onClose={() => setOpenModal(undefined)}
        />
      )}
      {isModalOpen === 'NotifyOrder' && (
        <SubmitModal
          title="Вы уверены, что хотите начать рассылку по заданию?"
          description=""
          kind="primary"
          submitText="Запустить"
          cancelText="Нет, вернуться назад"
          onSumbit={() => {
            setOpenModal(undefined)
            onNotifyLaunch?.(task.id)
          }}
          onClose={() => setOpenModal(undefined)}
        />
      )}
    </Flex>
  )
}
