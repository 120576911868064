import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex/Flex'

import styles from './Order.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

interface OrderPropsType {
  photoUrl: string
  title: string
  price: number
  id: number
  BonusPrice?: number
  onClick(): void
}

export const Order: FC<OrderPropsType> = ({ photoUrl, title, price, BonusPrice, id, onClick }) => {
  return (
    <Flex className={styles.Order} flexDirection="column" onClick={onClick}>
      <img className={styles.Photo} src={photoUrl} />
      <Flex className={styles.Info} flexDirection="column" justifyContent="space-between">
        <span className={styles.Title}>{title}</span>
        <span className={styles.Price}>{numberFormat.format(price)} ₽</span>
      </Flex>
      {BonusPrice && (
        <div className={styles.BonusPrice}>
          Доплата вам: <br />
          <strong>+ {BonusPrice} ₽</strong>
        </div>
      )}
      <div className={styles.Id}>{id}</div>
    </Flex>
  )
}
