import React, { FC, useMemo } from 'react'

import { CustomReporterType, OrderResponsesResponseDto } from 'src/shared/config/types'

import { Flex } from 'src/shared/ui/Flex'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'
import { Switch } from 'src/widgets/Switch'

import { AdminMediaSections } from '../AdminMediaSections/AdminMediaSections'
import { MediaSections } from '../MediaSections/MediaSections'

import styles from './ProgressStage.module.scss'

interface ProgressStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const ProgressStage: FC<ProgressStagePropsType> = ({ orderResponse }) => {
  const isSecondSectionRunning = useMemo(() => orderResponse.status === CustomReporterType.PAID, [])
  const isThirdSectionRunning = useMemo(() => orderResponse.status === CustomReporterType.COMPLETED_STEP_2, [])

  const AdminComponentWrapper: React.FC = () => <AdminMediaSections orderResponse={orderResponse} />
  const CustomerComponentWrapper: React.FC = () => <MediaSections orderResponse={orderResponse} />

  return (
    <Flex className={styles.ProgressStage} flexDirection="column">
      <Switch AdminComponent={AdminComponentWrapper} CustomerComponent={CustomerComponentWrapper} />
      {isSecondSectionRunning && (
        <>
          <div className={styles.Title}>2 раздел. Выкуп</div>
          <RemainingTime
            time={orderResponse.updateAt}
            duration={168}
            label="Блогер находится на этом этапе.
Подождите, пока он его выполнит."
            error="Время вышло"
            className={styles.RemainingTime}
          />
        </>
      )}
      {isThirdSectionRunning && (
        <>
          <div className={styles.Title}>3 раздел. Реклама</div>
          <RemainingTime
            time={orderResponse.secondStageTime || ''}
            duration={168}
            label="Блогер находится на этом этапе.
Подождите, пока он его выполнит."
            error="Время вышло"
            className={styles.RemainingTime}
          />
        </>
      )}
    </Flex>
  )
}
