import React, { FC, useCallback } from 'react'
import cn from 'classnames'

import styles from './Tabs.module.scss'

export interface TabType {
  tabId: string
  title: string
  badge?: number
}

interface TabsPropsType {
  className?: string
  activeTabId?: string
  tabs: TabType[]
  sessionStorageKey?: string
  onTabClick(tabId: string): void
}

export const Tabs: FC<TabsPropsType> = ({ className, activeTabId, tabs, sessionStorageKey, onTabClick }) => {
  const handleTabClick = useCallback(
    (tabId: string) => {
      onTabClick(tabId)
      if (sessionStorageKey) {
        sessionStorage.setItem(sessionStorageKey, tabId)
      }
    },
    [onTabClick],
  )

  return (
    <div className={cn(styles.Tabs, className)}>
      {tabs.map(({ tabId, title, badge }) => (
        <div
          key={tabId}
          className={cn(styles.Tab, tabId === activeTabId && styles.isActive)}
          onClick={() => handleTabClick(tabId)}
        >
          <span className={styles.Title}>{title}</span>
          {badge !== undefined && <span className={styles.Badge}>{badge}</span>}
        </div>
      ))}
    </div>
  )
}
