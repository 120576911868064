import React, { FC, useCallback } from 'react'
import toast from 'react-hot-toast'
import cn from 'classnames'

import { useUpload } from 'src/shared/model/useUpload'

import { Flex } from 'src/shared/ui/Flex'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { ProfileAvatarForm } from 'src/widgets/ProfileAvatarForm'

import { useBloggerForm } from '../../model/context'

import styles from '../BloggerForm/BloggerForm.module.scss'

interface PersonalInfoProps {
  showAvatar?: boolean
  showEmail?: boolean
  showNames?: boolean
  showPhone?: boolean
  showCountry?: boolean
  className?: string
}

export const PersonalInfo: FC<PersonalInfoProps> = ({
  showAvatar,
  showEmail,
  showNames,
  showPhone,
  showCountry,
  className,
}) => {
  const { methods, isLoading, setIsUploading } = useBloggerForm()
  const upload = useUpload()
  const { setValue, watch } = methods

  const uploadAvatar = useCallback(async (file: File) => {
    const uploadMediaToastId = toast.loading('Загружаем файл')
    setIsUploading(true)
    // setValue('avatar', ' ')

    try {
      const uploadedFile = await upload(file)
      setValue('avatar', uploadedFile)

      toast.success('Файл загружен', {
        id: uploadMediaToastId,
      })
    } catch (error) {
      toast.error(String(error) || 'Ошибка при загрузке файла', {
        id: uploadMediaToastId,
      })
    } finally {
      setIsUploading(false)
    }
  }, [])

  return (
    <Flex className={cn(styles.Block, className)} flexDirection="column" gap={16}>
      {showAvatar && (
        <ProfileAvatarForm
          name="avatar"
          firstName={watch('firstName')}
          className={styles.Avatar}
          disabled={isLoading}
          onChange={uploadAvatar}
        />
      )}
      {showEmail && <TextInputFormField id="email" name="email" kind="cabinet" label="Email" disabled={isLoading} />}
      {showNames && (
        <>
          <TextInputFormField id="firstName" name="firstName" kind="cabinet" label="Имя" disabled={isLoading} />
          <TextInputFormField id="lastName" name="lastName" kind="cabinet" label="Фамилия" disabled={isLoading} />
        </>
      )}
      {showPhone && <PhoneInputFormField id="phone" name="phone" kind="cabinet" label="Телефон" disabled={isLoading} />}
      {showCountry && (
        <>
          <TextInputFormField id="country" name="country" label="Страна" kind="cabinet" disabled={isLoading} />
          <TextInputFormField id="city" name="city" label="Город" kind="cabinet" disabled={isLoading} />
        </>
      )}
    </Flex>
  )
}
