import React, { FC } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { AdminAPI } from 'src/shared/api'
import { OrderByCustomerResponseDto } from 'src/shared/config/types'

import { CabinetTaskPageLayout } from 'src/widgets/CabinetTaskPageLayout'

export const AdminCabinetTask: FC = () => {
  const { taskId } = useParams()
  const orderId = Number(taskId)

  const { data: ordersData } = useQuery({
    queryKey: ['orders', orderId],
    queryFn: () => AdminAPI.api.managementControllerGetOrderById(orderId),
  })

  const order = ordersData?.data as OrderByCustomerResponseDto | undefined

  if (!order) {
    return null
  }

  return <CabinetTaskPageLayout order={order} orderId={orderId} />
}
