import { QueryClient, useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, UserActivateDto } from 'src/shared/api'

export const useUpdateUserMutation = (queryClient: QueryClient) => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { userId: number; data: UserActivateDto }
  >({
    mutationFn: ({ userId, data }) => AdminAPI.api.managementControllerUserActivated(userId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
  })
}
