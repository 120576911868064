import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { CABINET_ROUTE } from 'src/shared/config/consts'

import styles from './Logo.module.scss'

interface LogoProps {
  onClick: () => void
}

export const Logo: FC<LogoProps> = ({ onClick }) => {
  return (
    <Link to={CABINET_ROUTE} className={styles.Logo} onClick={onClick}>
      Marketiq
    </Link>
  )
}
