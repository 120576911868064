import React, { FC, HTMLInputAutoCompleteAttribute, useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import cn from 'classnames'

import { phoneRules } from 'src/shared/lib/phoneRules'

import { Flex } from '../Flex'
import { PhoneInputFormField } from '../PhoneInputFormField'
import { TextInputFormField } from '../TextInputFormField'

import styles from './BankDetailsFormField.module.scss'

export const DEFAULT_BANK_DETAILS = {
  accountNumber: '',
  accountPhone: '',
  bankName: '',
  names: '',
}

interface BankDetailsFormFieldPropsType {
  name: string
  disabled?: boolean
  className?: string
  kind?: 'smallCabinet' | 'cabinet'
  autocomplete?: HTMLInputAutoCompleteAttribute
}

export const BankDetailsFormField: FC<BankDetailsFormFieldPropsType> = ({
  name,
  disabled,
  kind,
  autocomplete,
  className,
}) => {
  const { control } = useFormContext()

  const {
    fields: bankDetailsFields,
    append: appendBankDetails,
    remove: removeBankDetails,
  } = useFieldArray({
    control,
    name,
  })

  const handleAddBankDetailsButtonClick = useCallback(() => {
    appendBankDetails(DEFAULT_BANK_DETAILS)
  }, [appendBankDetails])

  return (
    <Flex flexDirection="column" gap={16} className={cn(styles.Layout, className)}>
      {bankDetailsFields.map((item, index) => (
        <Flex key={item.id} flexDirection="column" gap={16}>
          <TextInputFormField
            id={`${name}.${index}.accountNumber`}
            name={`${name}.${index}.accountNumber`}
            label="Номер карты"
            placeholder="Номер карты"
            kind={kind}
            disabled={disabled}
            autoComplete={autocomplete}
          />
          <PhoneInputFormField
            id={`${name}.${index}.accountPhone`}
            name={`${name}.${index}.accountPhone`}
            label="Номер телефона"
            placeholder="Номер телефона"
            rules={phoneRules}
            kind={kind}
            disabled={disabled}
            autoComplete={autocomplete}
          />
          <TextInputFormField
            id={`${name}.${index}.bankName`}
            name={`${name}.${index}.bankName`}
            label="Название банка"
            placeholder="Название банка"
            kind={kind}
            disabled={disabled}
            autoComplete={autocomplete}
          />
          <TextInputFormField
            id={`${name}.${index}.names`}
            name={`${name}.${index}.names`}
            label="ФИО"
            placeholder="ФИО"
            kind={kind}
            disabled={disabled}
            autoComplete={autocomplete}
          />
          {bankDetailsFields.length > 1 && !disabled && (
            <button
              className={styles.Button}
              type="button"
              onClick={() => removeBankDetails(index)}
              disabled={disabled}
            >
              Удалить реквизиты
            </button>
          )}
          {index + 1 < bankDetailsFields.length && (
            <div className={styles.Separator}>
              <hr />
              <span>или</span>
              <hr />
            </div>
          )}
        </Flex>
      ))}
      {!disabled && bankDetailsFields.length < 2 && (
        <button className={styles.Button} type="button" onClick={handleAddBankDetailsButtonClick} disabled={disabled}>
          Добавить реквизиты
        </button>
      )}
    </Flex>
  )
}
