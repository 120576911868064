import React, { FC } from 'react'

import { OrderResponsesResponseDto } from 'src/shared/config/types'

import { Flex } from 'src/shared/ui/Flex'

import { Switch } from 'src/widgets/Switch'

import { AdminMediaSections } from '../AdminMediaSections/AdminMediaSections'
import { MediaSections } from '../MediaSections/MediaSections'

import styles from './CompletedStage.module.scss'

interface CompletedStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const CompletedStage: FC<CompletedStagePropsType> = ({ orderResponse }) => {
  const AdminComponentWrapper: React.FC = () => <AdminMediaSections orderResponse={orderResponse} />
  const CustomerComponentWrapper: React.FC = () => <MediaSections orderResponse={orderResponse} />

  return (
    <Flex className={styles.CompletedStage} flexDirection="column">
      <Switch AdminComponent={AdminComponentWrapper} CustomerComponent={CustomerComponentWrapper} />
    </Flex>
  )
}
