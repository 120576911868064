import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useInfiniteQuery } from '@tanstack/react-query'
import cn from 'classnames'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import { AdminAPI } from 'src/shared/api'
import { ReactComponent as Wind } from 'src/shared/assets/wind.svg'
import { CABINET_ROUTE, CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { BloggerOrderListResponseDto, CollectionType } from 'src/shared/config/types'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { EmptyState } from 'src/widgets/EmptyState'

import { Task } from '../Task/Task'

import styles from './BloggerCabinetTasks.module.scss'

enum TabType {
  ACTIVE_TASKS = 'ALL',
  COMPLETED_TASKS = 'COMPLETED',
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export const BloggerCabinetTasks: FC = () => {
  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTabId = searchParams.get('tab') || TabType.ACTIVE_TASKS

  const {
    data: ordersData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['orders', 'my', activeTabId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.ordersControllerFindAll({
        page: Number(pageParam),
        take: 20,
        reportCompleted: activeTabId === TabType.COMPLETED_TASKS,
        collection: activeTabId === TabType.MANUAL || activeTabId === TabType.AUTOMATIC ? activeTabId : undefined,
        _createAt: 'desc',
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages.length
      const totalPages = Math.ceil(lastPage.total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  const orders = useMemo(
    () => ordersData?.pages.flatMap((page) => page.data) || [],
    [ordersData],
  ) as unknown as BloggerOrderListResponseDto[]

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.ACTIVE_TASKS,
        title: 'Все',
      },
      {
        tabId: TabType.MANUAL,
        title: 'Отбор по критериям',
      },
      {
        tabId: TabType.AUTOMATIC,
        title: 'Гарантированное участие',
      },
      {
        tabId: TabType.COMPLETED_TASKS,
        title: 'Завершённые',
      },
    ],
    [],
  )

  const handleTabClick = useCallback(
    (newTabId: TabType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  const hasOrders = orders?.length >= 1

  return (
    <CabinetPageLayout className={styles.Layout}>
      <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={20}>
        <PageTitle>Мои задания</PageTitle>
        <a
          href="https://drive.google.com/file/d/1PMxvAt9h5wdzhsjLtwxXFZHuT6UMIZs7/"
          target="_blank"
          className={styles.EducationButton}
        >
          Пройти обучение
        </a>
      </Flex>

      <Tabs className={styles.Tabs} tabs={tabs} activeTabId={activeTabId} onTabClick={handleTabClick} />

      {hasOrders && (
        <Flex className={styles.Tasks} flexDirection="column" gap={20}>
          {orders.map((item) => (
            <Task key={item.id} task={item} onClick={() => openTask(item.id)} />
          ))}
        </Flex>
      )}
      {!hasOrders && !isLoading && (
        <CabinetPageLayout className={styles.Layout}>
          <EmptyState title="У вас сейчас нет активных заданий" icon={<Wind />} className={styles.EmptyState}>
            <CabinetGhostButton onClick={() => navigate(CABINET_ROUTE)} icon="left" className={styles.AddButton}>
              Перейти к списку
            </CabinetGhostButton>
          </EmptyState>
        </CabinetPageLayout>
      )}

      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}
    </CabinetPageLayout>
  )
}
