import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse } from 'src/shared/api'

export const useCancelOrderMutation = () => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { id: number }
  >({
    mutationFn: ({ id }) => AdminAPI.api.ordersControllerCancelOrder(id),
  })
}
