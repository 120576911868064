import React, { FC, ReactNode } from 'react'

import { ValueField } from 'src/shared/ui/ValueField'

import { Block } from '../Block/Block'
import { Section } from '../Section/Section'

interface PendingStageType {
  children: ReactNode
}

export const PendingStage: FC<PendingStageType> = ({ children }) => {
  return (
    <Section title="Вы откликнулись на заказ">
      <Block>
        <ValueField label="Ожидайте подтверждения от селлера">
          Когда селлер подтвердит ваше участие, вы сможете начать выполнять заказ.
        </ValueField>
      </Block>
      {children}
    </Section>
  )
}
