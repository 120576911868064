import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, ExcelReportResponseDto, HttpResponse } from 'src/shared/api'

export const useDownloadExcelMutation = () => {
  return useMutation<
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>,
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>
  >({
    mutationFn: () => AdminAPI.api.customersControllerGenerateOrdersExcel(),
  })
}
