import React, { FC, KeyboardEvent, useState } from 'react'
import cn from 'classnames'

import { ReactComponent as OutlineStar } from 'src/shared/assets/OutlineStar.svg'
import { ReactComponent as Star } from 'src/shared/assets/star.svg'

import styles from './Rating.module.scss'

interface RatingProps {
  className?: string
  value?: number
  onChange?: (value: number) => void
  max?: number
  disabled?: boolean
}

export const Rating: FC<RatingProps> = ({ className, value = 0, onChange, max = 5, disabled = false }) => {
  const [currentValue, setCurrentValue] = useState(value)

  const handleClick = (index: number) => {
    if (!disabled) {
      const newValue = index + 1
      setCurrentValue(newValue)
      onChange?.(newValue)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(index)
    }
  }

  return (
    <div className={cn(styles.Rating, className)}>
      {Array.from({ length: max }, (_, index) => (
        <div
          key={index}
          className={styles.Star}
          role="button"
          tabIndex={0}
          onClick={() => handleClick(index)}
          onKeyDown={(event) => handleKeyDown(event, index)}
          aria-disabled={disabled}
        >
          {currentValue > index ? <Star /> : <OutlineStar />}
        </div>
      ))}
    </div>
  )
}
