import { useMemo } from 'react'

export enum TabType {
  VERIFICATION_REQUESTS = 'VERIFICATION_REQUESTS',
  UPDATE_REQUESTS = 'UPDATE_REQUESTS',
  BLOGGERS = 'BLOGGERS',
  CUSTOMERS = 'CUSTOMERS',
  BLOCKED_BLOGGERS = 'BLOCKED_BLOGGERS',
  BLOCKED_CUSTOMERS = 'BLOCKED_CUSTOMERS',
  TEMPORARY_BLOCKED_BLOGGERS = 'TEMPORARY_BLOCKED_BLOGGERS',
}

export const getTabs = (usersCounts: Record<string, number>) => {
  return useMemo(
    () => [
      {
        tabId: TabType.VERIFICATION_REQUESTS,
        title: 'Заявки на верификацию',
        badge: usersCounts.numberToVerify,
      },
      {
        tabId: TabType.UPDATE_REQUESTS,
        title: 'Заявки на изменение',
        badge: usersCounts.approveChanges,
      },
      {
        tabId: TabType.BLOGGERS,
        title: 'Блогеры',
        badge: usersCounts.blogerCount,
      },
      {
        tabId: TabType.CUSTOMERS,
        title: 'Селлеры',
        badge: usersCounts.customerCount,
      },
      {
        tabId: TabType.BLOCKED_BLOGGERS,
        title: 'Черный список блогеров',
        badge: usersCounts.blogerBlockedCount,
      },
      {
        tabId: TabType.TEMPORARY_BLOCKED_BLOGGERS,
        title: 'Блогеры с временным баном',
        badge: usersCounts.customerBlockedCount,
      },
      {
        tabId: TabType.BLOCKED_CUSTOMERS,
        title: 'Заблокированные селлеры',
        badge: usersCounts.customerBlockedCount,
      },
    ],
    [usersCounts],
  )
}
