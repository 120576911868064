import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import { useUpload } from 'src/shared/model/useUpload'

import { UpdateOrderResponseDto } from 'src/shared/api'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { Uploader } from 'src/shared/ui/Uploader'
import { ValueField } from 'src/shared/ui/ValueField'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { useUpdateOrderResponseMutation } from '../../api/useUpdateOrderResponseMutation'
import { Block } from '../Block/Block'
import { Section } from '../Section/Section'

import styles from './ConfirmedStage.module.scss'

interface ConfirmedStagePropsType {
  orderId: number
  responseId: number
  startTime: string
  comment?: string
}

export const ConfirmedStage: FC<ConfirmedStagePropsType> = ({ orderId, responseId, startTime, comment }) => {
  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const [video, setVideo] = useState<string>()
  const [image, setImage] = useState<string>()

  const { mutate: updateOrderResponse, isPending: isUpdateOrderResponseLoading } = useUpdateOrderResponseMutation(
    orderId,
    responseId,
  )

  const isLoading = isUpdateOrderResponseLoading || isUploading

  const isButtonDisabled = isLoading || !video || !image

  const uploadMedia = async (file: File, type: 'video' | 'image') => {
    const uploadMediaToastId = toast.loading('Загружаем файл')
    setIsUploading(true)

    try {
      const uploadedFile = await upload(file)

      toast.success('Файл загружен', {
        id: uploadMediaToastId,
      })

      if (type === 'video') {
        setVideo(uploadedFile)
      } else {
        setImage(uploadedFile)
      }
    } catch (error) {
      toast.error(String(error) || 'Ошибка при загрузке файла', {
        id: uploadMediaToastId,
      })
    } finally {
      setIsUploading(false)
    }
  }

  const handleButtonClick = useCallback(async () => {
    const updateOrderToastId = toast.loading('Проверяем данные')

    const updateOrderResponseData = {
      step: 1,
      data: {
        orderVideo: video,
        orderScreenshot: image,
      } as UpdateOrderResponseDto,
    }

    updateOrderResponse(updateOrderResponseData, {
      onSuccess: () => {
        toast.success('Данные успешно отправлены', { id: updateOrderToastId })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Ошибка при загрузке файлов', { id: updateOrderToastId })
      },
    })
  }, [video, image, upload])

  return (
    <Section title="1 раздел. Заказ">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Инструкция:">
            Запишите свой экран, где видно, как вы ищите товар по КЛЮЧЕВОМУ СЛОВУ из ТЗ и оформляете товар на доставку в
            ваш ПВЗ. Видеозапись без ключевого слова не принимается, так как селлер не сможет оплатить вам за товар.
            Прикрепите видео и скриншот, чтобы отправить эту информацию селлеру, получить оплату и продолжить дальнейшее
            выполнение задания. <br />
            <br />
            Убедитесь что на скриншоте видно <strong>цену</strong> товара!
          </ValueField>
          {comment && (
            <Flex flexDirection="column" gap={8}>
              <p className={styles.CommentTitle}>Комментарий от селлера:</p>
              <div className={styles.Comment}>{comment}</div>
            </Flex>
          )}
          <Field id="video" label="Прикрепите видео заказа:" kind="cabinet">
            <Uploader
              type="video"
              file={video}
              onChange={(file) => uploadMedia(file, 'video')}
              isDisabled={isLoading}
            />
          </Field>
          <Field id="image" label="Прикрепите скриншот заказа:" kind="cabinet">
            <Uploader
              type="image"
              file={image}
              onChange={(file) => uploadMedia(file, 'image')}
              isDisabled={isLoading}
              placeholder="Загрузить скриншот"
            />
          </Field>
          <RemainingTime time={startTime} duration={24} label="Оставшееся время:" error="Время вышло" />
        </Block>
        <CabinetButton className={styles.CabinetButton} onClick={handleButtonClick} disabled={isButtonDisabled}>
          Отправить
        </CabinetButton>
      </Flex>
    </Section>
  )
}
