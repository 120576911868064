import React, { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet } from 'react-router'

import { SocketProvider } from 'src/app/lib/SocketProvider'

import { AuthPermission } from 'src/widgets/AuthPermission'

import { Header } from '../Header/Header'

import styles from './CabinetLayout.module.scss'

export const CabinetLayout: FC = () => {
  return (
    <AuthPermission>
      <SocketProvider>
        <div className={styles.CabinetLayout}>
          <Header />
          <div className={styles.Container}>
            <Outlet />
          </div>
        </div>
      </SocketProvider>

      <Toaster
        toastOptions={{
          className: styles.Toaster,
        }}
        containerClassName={styles.ToasterContainer}
      />
    </AuthPermission>
  )
}
