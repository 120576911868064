import React, { FC } from 'react'

import { ReactComponent as Human } from 'src/shared/assets/tariff_benefits_1.svg'
import { ReactComponent as Star } from 'src/shared/assets/tariff_benefits_2.svg'
import { ReactComponent as Comment } from 'src/shared/assets/tariff_benefits_3.svg'

import { Flex } from 'src/shared/ui/Flex'

import styles from './BenefitsBlock.module.scss'

export const BenefitsBlock: FC = () => {
  return (
    <Flex flexDirection="column" className={styles.Benefits}>
      <h2 className={styles.Benefits__title}>Вы получаете:</h2>
      <ul className={styles.BenefitsList}>
        <li className={styles.BenefitsItem}>
          <Human className={styles.BenefitsItem__Checkbox} />
          <span>Reels & stories у блогера</span>
        </li>
        <li className={styles.BenefitsItem}>
          <Star className={styles.BenefitsItem__Checkbox} />
          <span>Отзыв с фото</span>
        </li>
        <li className={styles.BenefitsItem}>
          <Comment className={styles.BenefitsItem__Checkbox} />
          <span>Выкуп по ключевой фразе</span>
        </li>
      </ul>
    </Flex>
  )
}
