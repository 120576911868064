import React, { FC } from 'react'

import { Icon } from 'src/shared/ui/Icon'

import styles from './SupportLink.module.scss'

export const SupportLink: FC = () => {
  return (
    <a className={styles.SupportLink} href="https://t.me/marketiq_support" target="_blank" rel="noreferrer">
      Техподдержка
      <Icon name="telegram-small" />
    </a>
  )
}
