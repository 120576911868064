import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { CabinetChangePassword } from '../CabinetChangePassword/CabinetChangePassword'

import styles from './CabinetSettings.module.scss'

export const CabinetSettings: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Изменить пароль</PageTitle>
      </Flex>
      <CabinetChangePassword />
    </CabinetPageLayout>
  )
}
