import React, { FC, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { AdminAPI, ProductResponseDto } from 'src/shared/api'

import { SelectFormField, SelectFormFieldPropsType } from 'src/shared/ui/SelectFormField'

type ProductSelectFormFieldPropsType = Omit<SelectFormFieldPropsType, 'options'>

export const ProductSelectFormField: FC<ProductSelectFormFieldPropsType> = (props) => {
  const { data: productsData } = useQuery({
    queryKey: ['product-select'],
    // TODO Вероятно здесь пагинация не нужна
    queryFn: () => AdminAPI.api.productsControllerFindAll({ page: 1, take: 200, _createAt: 'desc' }),
  })

  const products = useMemo<ProductResponseDto[]>(() => productsData?.data?.data ?? [], [productsData])

  const options = useMemo(
    () =>
      products.map((item) => ({
        value: String(item.id),
        label: item.title,
      })),
    [products],
  )

  return <SelectFormField options={options} {...props} />
}
