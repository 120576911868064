import { useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { AdminAPI, ProductResponseDto } from 'src/shared/api'
import { ProductStatusType } from 'src/shared/config/types'

export const useProducts = (status: ProductStatusType, search: string) => {
  const [productsCounts, setProductsCounts] = useState<Record<string, number>>({})

  const {
    data: productsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['products', status, search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.managementControllerFindAllProducts({
        page: Number(pageParam),
        take: 20,
        status,
        search,
        _createAt: 'desc',
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      let total

      switch (status) {
        case ProductStatusType.CONFIRMED:
          total = lastPage.totalConfirmed || 20
          break
        case ProductStatusType.PENDING:
          total = lastPage.totalPending || 20
          break
        case ProductStatusType.REJECTED:
          total = lastPage.totalRejected || 20
          break
        default:
          total = lastPage.total
          break
      }

      const currentPage = pages.length
      const totalPages = Math.ceil(total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  useEffect(() => {
    if (productsData?.pages[0]) {
      const { data, ...newProductsCounts } = productsData.pages[0]

      if (newProductsCounts) {
        setProductsCounts(newProductsCounts)
      }
    }
  }, [productsData])

  const products = useMemo(
    () => productsData?.pages.flatMap((page) => page.data) || [],
    [productsData],
  ) as unknown as ProductResponseDto[]

  return {
    products,
    productsCounts,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  }
}
