import { FC, ReactNode } from 'react'

import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'
import { ProgressBar, ProgressBarPropsType, StagesEnum } from 'src/shared/ui/ProgressBar/ProgressBar'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import styles from './ProgressBlogger.module.scss'

interface ProgressBloggerPropsType extends BloggerPropsType, ProgressBarPropsType {
  onIntegrationDetailClick(): void
  children?: ReactNode
}

export const ProgressBlogger: FC<ProgressBloggerPropsType> = ({
  stage,
  onIntegrationDetailClick,
  children,
  reportUpdateAt,
  secondStageTime,
  ...props
}) => {
  return (
    <Blogger {...props}>
      <Flex className={styles.Container} flexDirection="column" alignItems="center">
        <ProgressBar stage={stage} />
        <span className={styles.Text}>{stage === StagesEnum.STAGE_1 ? 'Делает выкуп' : 'Делает рекламу'}</span>
        {(reportUpdateAt || secondStageTime) && (
          <RemainingTime
            durationType="days"
            duration={7}
            time={secondStageTime || reportUpdateAt || ''}
            label="Оставшееся время:"
            error="Время вышло"
            className={styles.RemainingTime}
          />
        )}
        <CabinetActionButton className={styles.Button} onClick={onIntegrationDetailClick}>
          Детали интеграции
        </CabinetActionButton>
      </Flex>
      {children}
    </Blogger>
  )
}
