import React, { FC, useCallback, useMemo, useState } from 'react'
import { DeepPartial, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useEffectOnce } from 'react-use'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useUpload } from 'src/shared/model/useUpload'
import { useUserInfo } from 'src/shared/model/useUser'

import {
  AdminAPI,
  BankDetailsDto,
  BloggerProfileDto,
  CommonMessageResponse,
  HttpResponse,
  TopicResponseDto,
  UpsertBloggerDto,
} from 'src/shared/api'
import { UserStatusType } from 'src/shared/config/types'

import { getBankDetailsChange, getTopicsChanges } from 'src/shared/lib/prepareBloggerFields'

import { DEFAULT_BANK_DETAILS } from 'src/shared/ui/BankDetailsFormField'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { CheckboxFormField } from 'src/shared/ui/CheckboxFormField'
import { Flex } from 'src/shared/ui/Flex'

import { BankDetailsSection, BloggerForm, BlogInfo, MediaList, PersonalInfo } from 'src/widgets/BloggerForm'
import { BloggerFormFields } from 'src/widgets/BloggerForm/ui/BloggerForm/BloggerForm'

import { generateRandomEmojis } from '../../lib/generateRandomEmojis'

import styles from './BloggerVerification.module.scss'

const FORM_ID = 'BloggerVerification'

interface BloggerVerificationType {
  profile?: BloggerProfileDto
  setActiveTabId: React.Dispatch<React.SetStateAction<string>>
}

export const BloggerVerification: FC<BloggerVerificationType> = ({ profile, setActiveTabId }) => {
  const { user } = useUserInfo()
  const queryClient = useQueryClient()
  const upload = useUpload()

  const [generatedEmojis, setGeneratedEmojis] = useState<string>('')
  const [isUploading, setIsUploading] = useState(false)

  if (!profile) {
    return null
  }

  const bankDetails = profile.bankDetails as unknown as BankDetailsDto[]
  const topics = profile.topics as unknown as TopicResponseDto[]

  const isBloggerActivated = profile?.status === UserStatusType.ACTIVATED
  const isBloggerIncomplete = profile?.status === UserStatusType.INCOMPLETE_DATA
  const isUnderReview = (!isBloggerActivated || isBloggerIncomplete) && profile?.isFullInfo

  const defaultValues = useMemo<DeepPartial<BloggerFormFields>>(
    () => ({
      avatar: profile.avatar || '',
      firstName: profile.firstName || '',
      country: profile.country || '',
      city: profile.city || '',
      instagram: profile.instagram || '',
      topics: topics.length ? topics.map((item) => ({ topicId: String(item.id) })) : [{ topicId: undefined }],
      bankDetails: bankDetails.length ? bankDetails : [DEFAULT_BANK_DETAILS],
      verifyInfo: {
        videoStatistics: profile.verifyInfo?.videoStatistics || '',
        genderStatistics: profile.verifyInfo?.genderStatistics || '',
        countriesStatistics: profile.verifyInfo?.countriesStatistics || '',
        lastStoryStatistics: profile.verifyInfo?.lastStoryStatistics || '',
        publicationStatistics: profile.verifyInfo?.publicationStatistics || '',
      },
      isMessageSent: isBloggerIncomplete ? true : false,
      isBotConnected: isBloggerIncomplete && profile?.tgUsername ? true : false,
    }),
    [profile],
  )

  const methods = useForm<BloggerFormFields>({ defaultValues })
  const { watch, setValue } = methods
  const values = watch()

  const updateBloggerMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpsertBloggerDto
  >({
    mutationFn: AdminAPI.api.bloggerControllerActivate,
  })

  const onSubmit = useCallback(
    async (data: BloggerFormFields) => {
      const updateBloggerToastId = toast.loading('Проверяем заявку')

      const verifyInfo = defaultValues.verifyInfo
      const newVerifyInfo = data.verifyInfo

      const { newTopics, deletedTopics } = getTopicsChanges(profile.topics, data.topics)
      const newBankDetails = getBankDetailsChange(profile.bankDetails, data.bankDetails)

      function getUpdatedValue<T>(defaultValue: T, newValue: T): T | undefined {
        return defaultValue === newValue ? undefined : newValue
      }

      const upsertBloggerDto: UpsertBloggerDto = {
        avatar: getUpdatedValue(defaultValues.avatar, data.avatar),
        city: getUpdatedValue(defaultValues.city, data.city),
        country: getUpdatedValue(defaultValues.country, data.country),
        instagram: getUpdatedValue(defaultValues.instagram, data.instagram),
        followersCount: getUpdatedValue(profile.followersCount, Number(data.followersCount)),
        coveragesCount: getUpdatedValue(profile.coveragesCount, Number(data.coveragesCount)),
        blogTopics: {
          create: newTopics.map(({ topicId }) => ({ topicId: Number(topicId) })),
          delete: deletedTopics.map((topicId) => ({ topicId: Number(topicId) })),
        },
        bankDetails: newBankDetails as BankDetailsDto[],
        verifyInfo: {
          videoStatistics: getUpdatedValue(verifyInfo?.videoStatistics, newVerifyInfo?.videoStatistics),
          genderStatistics: getUpdatedValue(verifyInfo?.genderStatistics, newVerifyInfo?.genderStatistics),
          countriesStatistics: getUpdatedValue(verifyInfo?.countriesStatistics, newVerifyInfo?.countriesStatistics),
          lastStoryStatistics: getUpdatedValue(verifyInfo?.lastStoryStatistics, newVerifyInfo?.lastStoryStatistics),
          publicationStatistics: getUpdatedValue(
            verifyInfo?.publicationStatistics,
            newVerifyInfo?.publicationStatistics,
          ),
        },
      }

      updateBloggerMutation.mutate(upsertBloggerDto, {
        onSuccess: () => {
          toast.success('Заявка на верификацию успешно отправлена! ✨', {
            id: updateBloggerToastId,
          })
          setActiveTabId('personal')
          queryClient.invalidateQueries({ queryKey: ['profile'] })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при создании заявки', {
            id: updateBloggerToastId,
          })
        },
      })
    },
    [upload, updateBloggerMutation],
  )

  const checkTgVerifyMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    undefined
  >({
    mutationFn: AdminAPI.api.usersControllerTgVerify,
  })

  const handleVerifyClick = () => {
    const checkTgVerifyToastId = toast.loading('Проверяем подключение Telegram-бот')

    checkTgVerifyMutation.mutate(undefined, {
      onSuccess: async (response) => {
        const { status } = await response.json()

        if (status) {
          toast.success('Telegram-бот подключён! ✨', {
            id: checkTgVerifyToastId,
          })
          setValue('isBotConnected', Boolean(status))
        } else {
          toast.error('Telegram-бот не подключён', {
            id: checkTgVerifyToastId,
          })
        }
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка во время проверки', {
          id: checkTgVerifyToastId,
        })
      },
    })
  }

  useEffectOnce(() => {
    const emojis = generateRandomEmojis(4)
    setGeneratedEmojis(emojis)
  })

  const isLoading = updateBloggerMutation.isPending || checkTgVerifyMutation.isPending || isUploading

  const isTopicsValid = useMemo(() => values.topics.every((item) => item.topicId !== undefined), [values])

  const isBankDetailsValid = useMemo(
    () => values.bankDetails.every((item) => item.accountNumber && item.accountPhone && item.bankName && item.names),
    [values],
  )

  const isButtonDisabled =
    !isTopicsValid ||
    !isBankDetailsValid ||
    !values.country ||
    !values.city ||
    !values.instagram ||
    !values.followersCount ||
    !values.isMessageSent ||
    !values.isBotConnected ||
    !values.verifyInfo?.countriesStatistics ||
    !values.verifyInfo?.genderStatistics ||
    !values.verifyInfo?.lastStoryStatistics ||
    !values.verifyInfo?.publicationStatistics ||
    !values.verifyInfo?.videoStatistics ||
    isLoading

  if (!isBloggerActivated && isUnderReview) {
    return (
      <div className={styles.Stub}>
        <p>✨ Заявка на верификацию отправлена! ✨</p>
        <p> Пожалуйста, ожидайте результатов – мы уведомим вас сразу после завершения проверки. 🕒</p>
      </div>
    )
  }

  if (isBloggerActivated) {
    return (
      <div className={styles.Stub}>
        <p>✨ Вы успешно прошли верификацию! ✨</p>
      </div>
    )
  }

  return (
    <Flex className={styles.Form}>
      <BloggerForm
        formId={FORM_ID}
        isLoading={isLoading}
        methods={methods}
        onSubmit={onSubmit}
        setIsUploading={setIsUploading}
      >
        <PersonalInfo showAvatar showCountry />
        <BlogInfo showInstagram showTopics showCoverages showFollowers />
        <BankDetailsSection />
        <MediaList isOnlyEditable />
        <Flex className={styles.InputsContainer} flexDirection="column">
          <Flex flexDirection="column" gap={16}>
            <span className={styles.Label}>Подтверждение в Instagram</span>
            <span className={styles.Text}>
              Напишите нам в Instagram{' '}
              <a className={styles.Link} href="https://www.instagram.com/marketiq.pr" target="_blank" rel="noreferrer">
                @marketiq.pr
              </a>{' '}
              и отправьте следующие смайлики: <span className={styles.Emojis}>{generatedEmojis}</span>
            </span>
          </Flex>
          <CheckboxFormField id="isMessageSent" name="isMessageSent" withHiddenLabel>
            Я отправил сообщение в Instagram
          </CheckboxFormField>
        </Flex>
        <Flex className={styles.InputsContainer} flexDirection="column">
          <Flex flexDirection="column" gap={16}>
            <span className={styles.Label}>Подключите нашего Telegram-бота</span>
            <span className={styles.Text}>
              Нажмите на ссылку ниже, чтобы подключить Telegram-бота. После завершения настройки нажмите кнопку
              «Проверить». Если подключение прошло успешно, галочка появится автоматически.
            </span>

            <a
              className={styles.Link}
              href={`${process.env.REACT_APP_TG_BOT_URL}?start=userId=${user?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Ссылка на бот
            </a>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <CheckboxFormField id="isBotConnected" name="isBotConnected" withHiddenLabel disabled>
              Я подключил бота
            </CheckboxFormField>
            <CabinetActionButton kind="ghost" className={styles.CheckButton} onClick={handleVerifyClick} type="button">
              Проверить
            </CabinetActionButton>
          </Flex>
        </Flex>
      </BloggerForm>

      <CabinetButton form={FORM_ID} className={styles.CabinetButton} disabled={isButtonDisabled}>
        Отправить на верификацию
      </CabinetButton>
    </Flex>
  )
}
