import React, { FC, useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import {
  AdminAPI,
  ApproveBlogerChangesResponseDto,
  ApproveChangesDto,
  CommonMessageResponse,
  HttpResponse,
  UserResponseDto,
} from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'
import { Modal } from 'src/shared/ui/Modal'
import { NumericInputField } from 'src/shared/ui/NumericInputField'

import styles from './UpdateRequest.module.scss'

interface UpdateRequestPropsType {
  user: UserResponseDto
  setActiveTabId(newTabId: string): void
}

export const UpdateRequest: FC<UpdateRequestPropsType> = ({ user, setActiveTabId }) => {
  const [updatedUserData, setUpdatedUserData] = useState<ApproveBlogerChangesResponseDto>()
  const [isModalOpen, setModalOpen] = useState(false)
  const queryClient = useQueryClient()

  const { bloger } = user

  if (!bloger) {
    return null
  }

  if (!bloger.approveId) {
    return null
  }

  const { data: userData } = useQuery({
    queryKey: ['users', 'approve-changes', bloger.approveId],
    queryFn: () => AdminAPI.api.managementControllerGetApproveBlogerChanges(bloger.approveId as number),
  })

  useEffect(() => {
    if (userData) {
      setUpdatedUserData(userData?.data)
    }
  }, [userData])

  const { mutate: updateUserStatusMutation, isPending: isLoadingStatusUpdate } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    ApproveChangesDto
  >({
    mutationFn: (data: ApproveChangesDto) =>
      AdminAPI.api.managementControllerApproveBlogerChanges(user.id as number, data),
    onSuccess: () => {
      console.log(user.id)

      setActiveTabId('PROFILE')
      queryClient.invalidateQueries({ queryKey: ['users', user.id] })
    },
  })

  const activateUser = useCallback(() => {
    const updateUserToastId = toast.loading('Обновляем статус пользователя')

    updateUserStatusMutation(
      { status: 'CONFIRMED' },
      {
        onSuccess: () => {
          toast.success('Заявка на изменение одобрена! ✨', { id: updateUserToastId })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при обновлении статуса пользователя', {
            id: updateUserToastId,
          })
        },
      },
    )
  }, [updateUserStatusMutation])

  const rejectUser = useCallback(() => {
    setModalOpen(false)
    const updateUserToastId = toast.loading('Обновляем статус пользователя')

    updateUserStatusMutation(
      { status: 'REJECTED' },
      {
        onSuccess: () => {
          toast.success('Заявка на изменение одобрена! ✨', { id: updateUserToastId })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при обновлении статуса пользователя', {
            id: updateUserToastId,
          })
        },
      },
    )
  }, [updateUserStatusMutation])

  return (
    <Flex flexDirection="column" gap={20} className={styles.Layout}>
      <Flex className={styles.Blocks}>
        <Flex flexDirection="column" gap={32} className={cn(styles.Block, styles.BlogInfo)}>
          <h2 className={styles.Title}>Данные блога</h2>
          <NumericInputField
            value={updatedUserData?.newFollowersCount}
            id="followersCount"
            name="followersCount"
            label="Количество подписчиков"
            kind="cabinet"
            readOnly
          />
          <NumericInputField
            value={updatedUserData?.newCoveragesCount}
            id="coveragesCount"
            name="coveragesCount"
            label="Количество охватов"
            kind="cabinet"
            readOnly
          />
        </Flex>

        <Flex flexDirection="column" gap={24} className={styles.Block}>
          <h2 className={styles.Title}>Скриншоты и видео статистики</h2>
          <Flex flexWrap="wrap" gap={16}>
            <Media
              type="video"
              src={updatedUserData?.newVideoStatistics}
              desciption="Видеозапись экрана общей статистики просмотров за 7,14,30 дней"
              className={styles.Media}
            />
            <Media
              type="image"
              src={updatedUserData?.newGenderStatistics}
              desciption="Скриншот статистики возраста аудитории и пола"
              className={styles.Media}
            />
            <Media
              type="image"
              src={updatedUserData?.newCountriesStatistics}
              desciption="Скриншот статистики городов аудитории"
              className={styles.Media}
            />
            <Media
              type="image"
              src={updatedUserData?.newLastStoryStatistics}
              desciption="Скриншот статистики последней истории, где прошло 24 ч и более"
              className={styles.Media}
            />
            <Media
              type="image"
              src={updatedUserData?.newPublicationStatistics}
              desciption="Cкриншот статистики последнего поста, где прошло 24ч и более"
              className={styles.Media}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex className={styles.Buttons} flexDirection="column" gap={8}>
        <CabinetActionButton kind="primary" disabled={isLoadingStatusUpdate} onClick={activateUser}>
          Подтвердить
        </CabinetActionButton>
        <CabinetActionButton
          kind="ghost"
          disabled={isLoadingStatusUpdate}
          type="button"
          onClick={() => setModalOpen(true)}
        >
          Отклонить
        </CabinetActionButton>
      </Flex>
      {isModalOpen && (
        <Modal
          className={styles.RejectModal}
          title="Отклонить заявку блогера?"
          size="small"
          onClose={() => setModalOpen(false)}
        >
          <Flex className={styles.Buttons} flexDirection="column" alignSelf="center" gap={8}>
            <CabinetActionButton kind="danger" onClick={rejectUser}>
              Отклонить
            </CabinetActionButton>
            <CabinetActionButton kind="ghost" onClick={() => setModalOpen(false)}>
              Отмена
            </CabinetActionButton>
          </Flex>
        </Modal>
      )}
    </Flex>
  )
}
