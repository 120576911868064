import React, { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, ExcelReportResponseDto, HttpResponse } from 'src/shared/api'
import { ReactComponent as Warning } from 'src/shared/assets/warning.svg'
import { ReactComponent as Wind } from 'src/shared/assets/wind.svg'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import {
  BloggerType,
  CollectionType,
  CustomReporterType,
  OrderByCustomerResponseDto,
  RoleType,
} from 'src/shared/config/types'

import { getMarketplaceClass } from 'src/shared/lib/getMarketplaceClass'

import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Collection } from 'src/shared/ui/Collection'
import { Flex } from 'src/shared/ui/Flex'
import { Icon } from 'src/shared/ui/Icon'
import { OrderStatus } from 'src/shared/ui/OrderStatus'
import { StagesEnum } from 'src/shared/ui/ProgressBar/ProgressBar'
import { Tabs, TabType } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { EmptyState } from 'src/widgets/EmptyState'
import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { CommentsModal } from '../CommentsModal/CommentsModal'
import { DeleteReportButton } from '../DeleteReportButton/DeleteReportButton'
import { PaymentSentBlogger } from '../PaymentSentBlogger/PaymentSentBlogger'
import { ProgressBlogger } from '../ProgressBlogger/ProgressBlogger'
import { ReferenceModal } from '../ReferenceModal/ReferenceModal'
import { RejectedBlogger } from '../RejectedBlogger/RejectedBlogger'
import { RespondedBlogger } from '../RespondedBlogger/RespondedBlogger'
import { SubmittedTheReportBlogger } from '../SubmittedTheReportBlogger/SubmittedTheReportBlogger'
import { WaitingForPaymentBlogger } from '../WaitingForPaymentBlogger/WaitingForPaymentBlogger'

import styles from './CabinetTaskPageLayout.module.scss'

interface CabinetTaskPageLayoutPropsType {
  order: OrderByCustomerResponseDto
  orderId: number
  onConfirm?(item: BloggerType): void
  onReject?(item: BloggerType): void
}

export const CabinetTaskPageLayout: FC<CabinetTaskPageLayoutPropsType> = ({ order, orderId, onConfirm, onReject }) => {
  const [isReferenceModalOpen, setReferenceModalOpen] = useState(false)
  const [commentsModal, setCommentsModal] = useState<{ open: boolean; data: string[]; from: 'blogger' | 'customer' }>({
    open: false,
    data: [],
    from: 'customer',
  })
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const handleOpenReference = useCallback(() => setReferenceModalOpen(true), [])
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])
  const openResponse = useCallback(
    (reportId: number) => navigate(`${CABINET_TASKS_ROUTE}/${orderId}/responses/${reportId}`),
    [orderId, navigate],
  )

  const handleTabClick = useCallback(
    (newTabId: CustomReporterType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const pushToTemporaryMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    number
  >({
    mutationFn: async (userId: number) => AdminAPI.api.managementControllerPushToTemporaryBlocked(userId),
  })

  const handlePushToTemporary = useCallback((userId: number | undefined, isBlocked: boolean) => {
    if (userId) {
      const pushToTemporaryToastId = toast.loading(isBlocked ? 'Разблокируем пользователя' : 'Блокируем пользователя ')
      pushToTemporaryMutation.mutate(userId, {
        onSuccess: () => {
          toast.success(isBlocked ? 'Пользователь разблокирован' : 'Пользователь заблокирован!', {
            id: pushToTemporaryToastId,
          })
          queryClient.invalidateQueries({ queryKey: ['orders', orderId] })
        },
        onError: () => {
          toast.error(isBlocked ? 'Ошибка при разблокировки пользователя' : 'Ошибка при блокировки пользователя!', {
            id: pushToTemporaryToastId,
          })
        },
      })
    }
  }, [])

  const activeTabId =
    searchParams.get('tab') ||
    (order.collection === CollectionType.MANUAL ? CustomReporterType.PENDING : CustomReporterType.CONFIRMED)

  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const currentBloggers = useMemo(
    () =>
      (order.blogers ?? []).filter((item) => {
        if (activeTabId === CustomReporterType.PAID) {
          return (
            item.reportStatus === CustomReporterType.PAID || item.reportStatus === CustomReporterType.COMPLETED_STEP_2
          )
        }

        if (activeTabId === CustomReporterType.CONFIRMED) {
          return item.reportStatus === CustomReporterType.CONFIRMED || item.reportStatus === CustomReporterType.RESET
        }

        return item.reportStatus === activeTabId
      }),
    [order, activeTabId],
  )

  const tabs = useMemo<TabType[]>(
    () => [
      ...(order.collection === CollectionType.MANUAL
        ? [
            {
              tabId: CustomReporterType.REJECTED,
              title: 'Отклонены',
              badge: order.customReportersStatistic?.REJECTED,
            },
            {
              tabId: CustomReporterType.PENDING,
              title: 'Откликнулись',
              badge: order.customReportersStatistic?.PENDING,
            },
          ]
        : []),
      {
        tabId: CustomReporterType.CONFIRMED,
        title: 'Оформляют заказ',
        badge: (order.customReportersStatistic?.CONFIRMED || 0) + (order.customReportersStatistic?.RESET || 0),
      },
      {
        tabId: CustomReporterType.WAIT_PAYMENT,
        title: 'Ждут оплаты',
        badge: order.customReportersStatistic?.WAIT_PAYMENT,
      },
      {
        tabId: CustomReporterType.WAIT_PAY_CONFIRM,
        title: 'Оплата отправлена',
        badge: order.customReportersStatistic?.WAIT_PAY_CONFIRM,
      },
      {
        tabId: CustomReporterType.PAID,
        title: 'Выполняют задание',
        badge: (order.customReportersStatistic?.PAID || 0) + (order.customReportersStatistic?.COMPLETED_STEP_2 || 0),
      },
      {
        tabId: CustomReporterType.COMPLETED,
        title: 'Сдали отчет',
        badge: order.customReportersStatistic?.COMPLETED,
      },
    ],
    [order],
  )

  const renderBlogger = useCallback(
    (item: BloggerType) => {
      const { reportId, reportStatus } = item
      const bloggerProps = item as BloggerPropsType

      const renderDeleteReportButton = <DeleteReportButton reportId={reportId} orderId={orderId} />

      const renderBlockButton = (
        <CabinetActionButton
          kind="ghost"
          onClick={() => handlePushToTemporary(bloggerProps.userId, bloggerProps.userStatus === 'TEMPORARY_BLOCKED')}
        >
          {bloggerProps.userStatus === 'TEMPORARY_BLOCKED' ? 'Разблокировать' : 'Заблокировать'}
        </CabinetActionButton>
      )

      if (reportStatus === CustomReporterType.REJECTED) {
        return (
          <RejectedBlogger
            key={reportId}
            role={role}
            onConfirmClick={onConfirm ? () => onConfirm(item) : undefined}
            {...bloggerProps}
          >
            {renderDeleteReportButton}
          </RejectedBlogger>
        )
      }

      if (reportStatus === CustomReporterType.PENDING) {
        return (
          <RespondedBlogger
            key={reportId}
            role={role}
            {...bloggerProps}
            onConfirmClick={onConfirm ? () => onConfirm(item) : undefined}
            onRejectClick={onReject ? () => onReject(item) : undefined}
          >
            {renderDeleteReportButton}
          </RespondedBlogger>
        )
      }

      if (reportStatus === CustomReporterType.CONFIRMED || reportStatus === CustomReporterType.RESET) {
        return (
          <div className={styles.ConfirmedBlogger} key={reportId}>
            <Blogger {...bloggerProps}>
              {bloggerProps.reportUpdateAt && (
                <RemainingTime
                  durationType="hours"
                  duration={24}
                  time={bloggerProps.reportUpdateAt}
                  label="Оставшееся время:"
                  error="Время вышло"
                  className={styles.RemainingTime}
                />
              )}
              {role === RoleType.SUPER_ADMIN && renderBlockButton}
            </Blogger>
            {renderDeleteReportButton}
            {bloggerProps.comments?.customers && role === RoleType.SUPER_ADMIN && (
              <Warning
                className={cn(styles.Warning, styles.AdminWarning)}
                onClick={(event) => {
                  event.preventDefault()
                  setCommentsModal({ open: true, data: bloggerProps.comments?.customers || [], from: 'customer' })
                }}
              />
            )}
          </div>
        )
      }

      if (reportStatus === CustomReporterType.WAIT_PAYMENT) {
        return (
          <WaitingForPaymentBlogger
            key={reportId}
            role={role}
            {...bloggerProps}
            onIntegrationDetailClick={() => openResponse(reportId)}
          >
            {role === RoleType.SUPER_ADMIN && renderBlockButton}
            {renderDeleteReportButton}
            {(item.comment || bloggerProps.comments?.bloggers) && (
              <Warning
                className={cn(styles.Warning, role === RoleType.SUPER_ADMIN && styles.AdminWarning)}
                onClick={(event) => {
                  event.preventDefault()
                  setCommentsModal({
                    open: true,
                    data: bloggerProps.comments?.bloggers || [item.comment],
                    from: 'blogger',
                  })
                }}
              />
            )}
          </WaitingForPaymentBlogger>
        )
      }

      if (reportStatus === CustomReporterType.WAIT_PAY_CONFIRM) {
        return (
          <PaymentSentBlogger
            key={reportId}
            role={role}
            {...bloggerProps}
            onIntegrationDetailClick={() => openResponse(reportId)}
          >
            {renderDeleteReportButton}
            {role === RoleType.SUPER_ADMIN && renderBlockButton}
          </PaymentSentBlogger>
        )
      }

      if (reportStatus === CustomReporterType.PAID || reportStatus === CustomReporterType.COMPLETED_STEP_2) {
        return (
          <ProgressBlogger
            key={reportId}
            {...bloggerProps}
            stage={reportStatus === CustomReporterType.PAID ? StagesEnum.STAGE_1 : StagesEnum.STAGE_2}
            onIntegrationDetailClick={() => openResponse(reportId)}
          >
            {renderDeleteReportButton}
            {role === RoleType.SUPER_ADMIN && renderBlockButton}
          </ProgressBlogger>
        )
      }

      return (
        <SubmittedTheReportBlogger
          key={reportId}
          role={role}
          {...bloggerProps}
          onIntegrationDetailClick={() => openResponse(reportId)}
        >
          {renderDeleteReportButton}
          {role === RoleType.SUPER_ADMIN && renderBlockButton}
        </SubmittedTheReportBlogger>
      )
    },
    [role, onConfirm, onReject, openResponse],
  )

  const downloadExcelMutation = useMutation<
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>,
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>,
    { orderId: number }
  >({
    mutationFn: ({ orderId }) =>
      role === RoleType.SUPER_ADMIN
        ? AdminAPI.api.managementControllerGenerateOrdersExcel({ orderId })
        : AdminAPI.api.customersControllerGenerateOrdersExcel({ orderId }),
  })

  const handleDownloadExcel = useCallback((orderId: number) => {
    const updateCustomerToastId = toast.loading('Подготавливаем Excel файл')

    downloadExcelMutation.mutate(
      { orderId },
      {
        onSuccess: (data) => {
          const link = document.createElement('a')
          link.href = data.data.link
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast.success('Файл скачан! ✨', {
            id: updateCustomerToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Произошла ошибка при создании файла', {
            id: updateCustomerToastId,
          })
        },
      },
    )
  }, [])

  if (!order) {
    return null
  }

  if (!order.customReportersStatistic) {
    return null
  }

  const confirmedCount =
    order.customReportersStatistic.COMPLETED +
    order.customReportersStatistic.RESET +
    order.customReportersStatistic.COMPLETED_STEP_2 +
    order.customReportersStatistic.CONFIRMED +
    order.customReportersStatistic.PAID +
    order.customReportersStatistic.PENDING +
    order.customReportersStatistic.WAIT_PAYMENT +
    order.customReportersStatistic.WAIT_PAY_CONFIRM

  return (
    <CabinetPageLayout>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <Flex className={styles.Header} isFluid>
          <Flex className={styles.Main} alignItems="center">
            <img className={styles.Photo} src={order.photoUrl} />
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Title}>
                {order.title}
                <span className={styles.Id}>{order.orderId}</span>
              </span>
              <Flex alignItems="baseline" gap={16} flexWrap="wrap">
                <span className={cn(styles.Marketplace, getMarketplaceClass(order.marketplaceName))}>
                  {order.marketplaceName}
                </span>
                <Collection collection={order.collection!} />
                <span className={styles.Link} onClick={handleOpenReference}>
                  Техническое задание
                </span>
                <span className={styles.Download} onClick={() => handleDownloadExcel(order.orderId)}>
                  <Icon name="download" />
                  Отчёт
                </span>
              </Flex>
            </Flex>
          </Flex>
          <OrderStatus
            status={order.status!}
            collection={order.collection!}
            confirmedCount={confirmedCount}
            maxParticipants={order.maxParticipants!}
          />
        </Flex>
      </Flex>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={handleTabClick} />
      <Flex className={styles.Bloggers} alignItems="flex-start" gap={16} flexWrap="wrap">
        {currentBloggers.map(renderBlogger)}
        {currentBloggers.length < 1 && (
          <EmptyState title="У вас пока нет откликов на данном статусе" icon={<Wind />} className={styles.EmptyState} />
        )}
      </Flex>
      {isReferenceModalOpen && <ReferenceModal order={order} onClose={() => setReferenceModalOpen(false)} />}
      {commentsModal.open && (
        <CommentsModal
          from={commentsModal.from}
          comments={commentsModal.data}
          onClose={() => {
            setCommentsModal({ open: false, data: [], from: 'customer' })
          }}
        />
      )}
    </CabinetPageLayout>
  )
}
