import React, { FC, useState } from 'react'
import { useDebounce } from 'react-use'
import cn from 'classnames'

import { Icon } from 'src/shared/ui/Icon'
import { Input } from 'src/shared/ui/Input'

import styles from './Search.module.scss'

interface SearchProps {
  className?: string
  placeholder: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

export const Search: FC<SearchProps> = ({ className, placeholder, setSearchValue }) => {
  const [value, setValue] = useState('')

  useDebounce(
    () => {
      setSearchValue(value)
    },
    500,
    [value],
  )

  return (
    <Input
      kind="cabinet"
      placeholder={placeholder}
      className={cn(styles.Input, className)}
      postfix={<Icon name="search" />}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  )
}
