import React, { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import { AdminAPI } from 'src/shared/api'
import { ReactComponent as File } from 'src/shared/assets/file_add.svg'
import { CABINET_TASKS_NEW_ROUTE, CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CollectionType, CustomerOrderListResponseDto, OrderStatusType } from 'src/shared/config/types'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Tabs } from 'src/shared/ui/Tabs'
import { Task } from 'src/shared/ui/Task'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { EmptyState } from 'src/widgets/EmptyState'
import { Search } from 'src/widgets/Search'

import { useCancelOrderMutation } from '../../api/useCancelOrderMutation'
import { useChangeOrderStatusMutation } from '../../api/useChangeOrderStatusMutation'
import { useDownloadExcelMutation } from '../../api/useDownloadExcelMutation'
import { useEarlyLaunchMutation } from '../../api/useEarlyLaunchMutation'

import styles from './CabinetTasks.module.scss'

const TAB_CONFIG = {
  [CollectionType.MANUAL]: {
    title: 'Ручной сбор',
    status: undefined,
    collection: CollectionType.MANUAL,
  },
  [CollectionType.AUTOMATIC]: {
    title: 'Автоматический сбор',
    status: 'IN_PROGRESS',
    collection: CollectionType.AUTOMATIC,
  },
  COMPLETED: {
    title: 'Завершённые',
    status: 'COMPLETED',
    collection: undefined,
  },
  CANCELED: {
    title: 'Отменённые',
    status: 'CANCELED',
    collection: undefined,
  },
}

export const CabinetTasks: FC = () => {
  const navigate = useNavigate()
  const handleAddTaskClick = useCallback(() => navigate(CABINET_TASKS_NEW_ROUTE), [navigate])
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])
  const [search, setSearchValue] = useState('')

  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useSearchParams()
  const activeTabId = searchParams.get('tab') || CollectionType.MANUAL

  const tabs = useMemo(
    () =>
      Object.entries(TAB_CONFIG).map(([tabId, config]) => ({
        tabId,
        title: config.title,
      })),
    [],
  )

  const handleTabClick = useCallback(
    (newTabId: CollectionType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const fetchOrders = async ({ pageParam = 1 }) => {
    const config = TAB_CONFIG[activeTabId as keyof typeof TAB_CONFIG]
    const response = await AdminAPI.api.ordersControllerFindAll({
      page: Number(pageParam),
      take: 10,
      collection: config.collection,
      status: config.status,
      _createAt: 'desc',
      search,
    })
    return response.data
  }

  const {
    data: ordersData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['orders', activeTabId, search],
    queryFn: fetchOrders,
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages.length
      const totalPages = Math.ceil(lastPage.total / 10)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  const orders = useMemo(
    () => ordersData?.pages.flatMap((page) => page.data) ?? [],
    [ordersData],
  ) as unknown as CustomerOrderListResponseDto[]

  const cancelOrderMutation = useCancelOrderMutation()
  const changeOrderStatusMutation = useChangeOrderStatusMutation()
  const downloadExcelMutation = useDownloadExcelMutation()
  const earlyLaunchMutation = useEarlyLaunchMutation()

  const handleCancelOrder = useCallback(
    (id: number) => {
      const cancelOrderToastId = toast.loading('Отменяем задание')
      cancelOrderMutation.mutate(
        { id },
        {
          onSuccess: () => {
            toast.success('Задание отменено! ✨', {
              id: cancelOrderToastId,
            })
            queryClient.invalidateQueries({ queryKey: ['orders', activeTabId] })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при отмене задания', {
              id: cancelOrderToastId,
            })
          },
        },
      )
    },
    [cancelOrderMutation, activeTabId],
  )

  const handleStatusChange = useCallback(
    (id: number, status: OrderStatusType) => {
      changeOrderStatusMutation.mutate(
        { id, variables: { status } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders', activeTabId] })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при изменении статуса')
          },
        },
      )
    },
    [changeOrderStatusMutation, activeTabId],
  )

  const handleDownloadExcel = useCallback(() => {
    const updateCustomerToastId = toast.loading('Подготавливаем Excel файл')

    downloadExcelMutation.mutate(undefined, {
      onSuccess: (data) => {
        const link = document.createElement('a')
        link.href = data.data.link
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.success('Файл скачан! ✨', {
          id: updateCustomerToastId,
        })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при создании файла', {
          id: updateCustomerToastId,
        })
      },
    })
  }, [downloadExcelMutation])

  const handleEarlyLaunch = useCallback(
    (id: number) => {
      const earlyLaunchToastId = toast.loading('Досрочно запускаем задание ')

      earlyLaunchMutation.mutate(
        { id },
        {
          onSuccess: () => {
            toast.success('Задание досрочно запущено! ✨', {
              id: earlyLaunchToastId,
            })
            queryClient.invalidateQueries({ queryKey: ['orders', activeTabId] })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при запуске', {
              id: earlyLaunchToastId,
            })
          },
        },
      )
    },
    [earlyLaunchMutation, activeTabId],
  )

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  const hasOrders = orders?.length >= 1

  return (
    <CabinetPageLayout className={styles.Layout}>
      <Flex justifyContent="space-between" alignItems="center">
        <PageTitle>Мои задания</PageTitle>
        <Flex gap={8}>
          <CabinetGhostButton icon="download" onClick={handleDownloadExcel}>
            Отчёт по заданиям
          </CabinetGhostButton>
          <CabinetGhostButton onClick={handleAddTaskClick} icon="plus">
            Добавить задание
          </CabinetGhostButton>
        </Flex>
      </Flex>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={handleTabClick} />
      <Search setSearchValue={setSearchValue} placeholder="Поиск заданий по номеру, названию товара или артикулу" />
      {!hasOrders && activeTabId !== 'COMPLETED' && !isLoading ? (
        <CabinetPageLayout>
          <EmptyState title="У вас сейчас нет активных заказов" icon={<File />}>
            <CabinetGhostButton onClick={handleAddTaskClick} icon="plus" className={styles.AddButton}>
              Добавить задание
            </CabinetGhostButton>
          </EmptyState>
        </CabinetPageLayout>
      ) : (
        <Flex className={styles.Tasks} flexDirection="column" gap={20}>
          {orders.map((item) => {
            if (!item || !item.customReportersStatistic) {
              return null
            }
            return (
              <Task
                key={item.id}
                task={item}
                onClick={openTask}
                onStatusChange={handleStatusChange}
                onCancelOrder={handleCancelOrder}
                onEarlyLaunch={handleEarlyLaunch}
              />
            )
          })}
        </Flex>
      )}
      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}
    </CabinetPageLayout>
  )
}
