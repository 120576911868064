import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import logo from 'src/shared/assets/logo.svg'
import { CUSTOMER_LANDING_ROUTE } from 'src/shared/config/consts'

import styles from './Logo.module.scss'

interface LogoPropsType {
  className?: string
}

export const Logo: FC<LogoPropsType> = ({ className }) => {
  return (
    <Link to={CUSTOMER_LANDING_ROUTE} aria-label="Главная">
      <img className={cn(styles.Logo, className)} src={logo} alt="Логотип: Marketiq" />
    </Link>
  )
}
